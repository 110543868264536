import axios from 'axios'
import { KPIS_LIST_DATA_REQUEST, KPIS_LIST_DATA_SUCCESS, KPIS_LIST_DATA_FAIL } from '../constants/kpisConstants'

export const getKpisList = (startDate, endDate) => async (dispatch, getState) => {
  try {
    dispatch({ type: KPIS_LIST_DATA_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/kpis?start=${startDate}&end=${endDate}`, config)

    dispatch({ type: KPIS_LIST_DATA_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: KPIS_LIST_DATA_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
