export const INVESTOR_REGISTER_REQUEST = 'INVESTOR_REGISTER_REQUEST'
export const INVESTOR_REGISTER_SUCCESS = 'INVESTOR_REGISTER_SUCCESS'
export const INVESTOR_REGISTER_FAIL = 'INVESTOR_REGISTER_FAIL'
export const INVESTOR_REGISTER_RESET = 'INVESTOR_REGISTER_RESET'

export const INVESTOR_LIST_REQUEST = 'INVESTOR_LIST_REQUEST'
export const INVESTOR_LIST_SUCCESS = 'INVESTOR_LIST_SUCCESS'
export const INVESTOR_LIST_FAIL = 'INVESTOR_LIST_FAIL'
export const INVESTOR_LIST_RESET = 'INVESTOR_LIST_RESET'

export const INVESTOR_UPDATE_REQUEST = 'INVESTOR_UPDATE_REQUEST'
export const INVESTOR_UPDATE_SUCCESS = 'INVESTOR_UPDATE_SUCCESS'
export const INVESTOR_UPDATE_FAIL = 'INVESTOR_UPDATE_FAIL'
export const INVESTOR_UPDATE_RESET = 'INVESTOR_UPDATE_RESET'

export const INVESTOR_USER_REQUEST = 'INVESTOR_USER_REQUEST'
export const INVESTOR_USER_SUCCESS = 'INVESTOR_USER_SUCCESS'
export const INVESTOR_USER_FAIL = 'INVESTOR_USER_FAIL'
export const INVESTOR_USER_RESET = 'INVESTOR_USER_RESET'

export const INVESTOR_PRESCRIBERS_PROFILES_REQUEST = 'INVESTOR_PRESCRIBERS_PROFILES_REQUEST'
export const INVESTOR_PRESCRIBERS_PROFILES_SUCCESS = 'INVESTOR_PRESCRIBERS_PROFILES_SUCCESS'
export const INVESTOR_PRESCRIBERS_PROFILES_FAIL = 'INVESTOR_PRESCRIBERS_PROFILES_FAIL'
export const INVESTOR_PRESCRIBERS_PROFILES_RESET = 'INVESTOR_PRESCRIBERS_PROFILES_RESET'
