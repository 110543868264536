import {
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGOUT,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_FAIL,
	USER_REGISTER_RESET,
	USER_LIST_REQUEST,
	USER_LIST_SUCCESS,
	USER_LIST_FAIL,
	USER_LIST_RESET,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_DETAILS_FAIL,
	USER_DETAILS_RESET,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DELETE_FAIL,
	USER_DELETE_RESET,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAIL,
	USER_UPDATE_RESET,
	USER_REFRESH_TOKEN_REQUEST,
	USER_REFRESH_TOKEN_SUCCESS,
	USER_REFRESH_TOKEN_FAIL,
	USER_REFRESH_TOKEN_RESET,
	USER_UPDATE_MY_INFO_REQUEST,
	USER_UPDATE_MY_INFO_SUCCESS,
	USER_UPDATE_MY_INFO_FAIL,
	USER_UPDATE_MY_INFO_RESET,
	USER_RECOVERY_PASSWORD_REQUEST,
	USER_RECOVERY_PASSWORD_SUCCESS,
	USER_RECOVERY_PASSWORD_FAIL,
	USER_RECOVERY_PASSWORD_RESET,
	PROFILE_GET_NOTES_REQUEST,
	PROFILE_GET_NOTES_SUCCESS,
	PROFILE_GET_NOTES_FAIL,
	PROFILE_GET_NOTES_RESET,
	PROFILE_GET_NOTES_SUCCESS_ADDED,
	PROFILE_GET_NOTES_FAIL_RESET,
	PROFILE_GET_NOTES_REQUEST_ADD,
	USER_GET_NOTES_REQUEST,
	USER_GET_NOTES_SUCCESS,
	USER_GET_NOTES_FAIL,
	USER_GET_NOTES_RESET,
	USER_GET_NOTES_SUCCESS_ADDED,
	USER_GET_NOTES_FAIL_RESET,
	USER_GET_NOTES_REQUEST_ADD,
	USER_LOGIN_MFA_CODE_REQUEST,
	USER_LOGIN_MFA_CODE_SUCCESS,
	USER_LOGIN_MFA_CODE_FAIL,
	USER_LOGIN_MFA_CODE_RESET,
	USER_MFA_CODE_VALIDATE_REQUEST,
	USER_MFA_CODE_VALIDATE_SUCCESS,
	USER_MFA_CODE_VALIDATE_FAIL,
	USER_MFA_CODE_VALIDATE_RESET,
	USER_CONFIRM_PASSWORD_REQUEST,
	USER_CONFIRM_PASSWORD_SUCCESS,
	USER_CONFIRM_PASSWORD_FAIL,
	USER_CONFIRM_PASSWORD_RESET,
	USER_MFA_CODE_DISABLE_REQUEST,
	USER_MFA_CODE_DISABLE_SUCCESS,
	USER_MFA_CODE_DISABLE_FAIL,
	USER_MFA_CODE_DISABLE_RESET,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LOGIN_REQUEST:
			return { loadingUserInfo: true }
		case USER_LOGIN_SUCCESS:
			return { loadingUserInfo: false, userInfo: action.payload, successUserInfo: true }
		case USER_LOGIN_FAIL:
			return { loadingUserInfo: false, errorUserInfo: action.payload }
		case USER_LOGOUT:
			localStorage.removeItem('setCHGG')
			localStorage.removeItem('mfact')
			return {}
		default:
			return state
	}
}

export const userRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_REGISTER_REQUEST:
			return { loadingUserRegister: true }
		case USER_REGISTER_SUCCESS:
			return {
				loadingUserRegister: false,
				successUserRegister: true,
				user: action.payload,
			}
		case USER_REGISTER_FAIL:
			return {
				loadingUserRegister: false,
				errorUserRegister: action.payload,
			}
		case USER_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const userLoginWithMFAReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LOGIN_MFA_CODE_REQUEST:
			return { loadingUserLoginWithMFA: true }
		case USER_LOGIN_MFA_CODE_SUCCESS:
			return {
				loadingUserLoginWithMFA: false,
				successUserLoginWithMFA: true,
				userLoginWithMFA: action.payload,
			}
		case USER_LOGIN_MFA_CODE_FAIL:
			return {
				loadingUserLoginWithMFA: false,
				errorUserLoginWithMFA: action.payload,
			}
		case USER_LOGIN_MFA_CODE_RESET:
			return {}
		default:
			return state
	}
}

export const userDisableMFAReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_MFA_CODE_DISABLE_REQUEST:
			return { loadingUserDisableMFA: true }
		case USER_MFA_CODE_DISABLE_SUCCESS:
			return {
				loadingUserDisableMFA: false,
				successUserDisableMFA: true,
				userDisableMFA: action.payload,
			}
		case USER_MFA_CODE_DISABLE_FAIL:
			return {
				loadingUserDisableMFA: false,
				errorUserDisableMFA: action.payload,
			}
		case USER_MFA_CODE_DISABLE_RESET:
			return {}
		default:
			return state
	}
}

export const userMFACodeValidateReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_MFA_CODE_VALIDATE_REQUEST:
			return { loadingUserMFACodeValidate: true }
		case USER_MFA_CODE_VALIDATE_SUCCESS:
			return {
				loadingUserMFACodeValidate: false,
				successUserMFACodeValidate: true,
				userMFACodeValidate: action.payload,
			}
		case USER_MFA_CODE_VALIDATE_FAIL:
			return {
				loadingUserMFACodeValidate: false,
				errorUserMFACodeValidate: action.payload,
			}
		case USER_MFA_CODE_VALIDATE_RESET:
			return {}
		default:
			return state
	}
}

export const userConfirmPasswordReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_CONFIRM_PASSWORD_REQUEST:
			return { loadingUserConfirmPassword: true }
		case USER_CONFIRM_PASSWORD_SUCCESS:
			return {
				loadingUserConfirmPassword: false,
				successUserConfirmPassword: true,
				userConfirmPassword: action.payload,
			}
		case USER_CONFIRM_PASSWORD_FAIL:
			return {
				loadingUserConfirmPassword: false,
				errorUserConfirmPassword: action.payload,
			}
		case USER_CONFIRM_PASSWORD_RESET:
			return {}
		default:
			return state
	}
}

export const refreshTokenReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_REFRESH_TOKEN_REQUEST:
			return { loadingRefreshToken: true }
		case USER_REFRESH_TOKEN_SUCCESS:
			return {
				loadingRefreshToken: false,
				successRefreshToken: true,
			}
		case USER_REFRESH_TOKEN_FAIL:
			return {
				loadingRefreshToken: false,
				errorRefreshToken: action.payload,
			}
		case USER_REFRESH_TOKEN_RESET:
			return {}
		default:
			return state
	}
}

export const userListReducer = (state = { users: [] }, action) => {
	switch (action.type) {
		case USER_LIST_REQUEST:
			return { loadingUserList: true }
		case USER_LIST_SUCCESS:
			return {
				loadingUserList: false,
				successUserList: true,
				thatsIt: action.payload.length === 0 ? true : false,
				users: action.payload,
			}
		case USER_LIST_FAIL:
			return { loadingUserList: false, errorUserList: action.payload }
		case USER_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const userDetailsReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_DETAILS_REQUEST:
			return { loadingUserDetails: true }
		case USER_DETAILS_SUCCESS:
			return {
				loadingUserDetails: false,
				successUserDetails: true,
				userDetail: action.payload,
			}
		case USER_DETAILS_FAIL:
			return {
				loadingUserDetails: false,
				errorUserDetails: action.payload,
			}
		case USER_DETAILS_RESET:
			return {}
		default:
			return state
	}
}

export const userUpdateReducer = (state = { userUpdated: {} }, action) => {
	switch (action.type) {
		case USER_UPDATE_REQUEST:
			return { loadingUserUpdate: true }
		case USER_UPDATE_SUCCESS:
			return {
				loadingUserUpdate: false,
				successUserUpdate: true,
				userUpdated: action.payload,
			}
		case USER_UPDATE_FAIL:
			return { loadingUserUpdate: false, errorUserUpdate: action.payload }
		case USER_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const userDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_DELETE_REQUEST:
			return { loadingUserDelete: true }
		case USER_DELETE_SUCCESS:
			return { loadingUserDelete: false, successUserDelete: true }
		case USER_DELETE_FAIL:
			return { loadingUserDelete: false, errorUserDelete: action.payload }
		case USER_DELETE_RESET:
			return {}
		default:
			return state
	}
}

export const userUpdateMyInfoReducer = (state = { userUpdateMyInfo: {} }, action) => {
	switch (action.type) {
		case USER_UPDATE_MY_INFO_REQUEST:
			return { loadingUpdateMyInfo: true }
		case USER_UPDATE_MY_INFO_SUCCESS:
			return {
				loadingUpdateMyInfo: false,
				successUpdateMyInfo: true,
				userUpdateMyInfo: action.payload,
			}
		case USER_UPDATE_MY_INFO_FAIL:
			return { loadingUpdateMyInfo: false, errorUpdateMyInfo: action.payload }
		case USER_UPDATE_MY_INFO_RESET:
			return {}
		default:
			return state
	}
}

export const userRecoveryPasswordReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_RECOVERY_PASSWORD_REQUEST:
			return { loadingUserRecoveryPassword: true }
		case USER_RECOVERY_PASSWORD_SUCCESS:
			return { loadingUserRecoveryPassword: false, user: action.payload, successUserRecoveryPassword: true }
		case USER_RECOVERY_PASSWORD_FAIL:
			return { loadingUserRecoveryPassword: false, errorUserRecoveryPassword: action.payload }
		case USER_RECOVERY_PASSWORD_RESET:
			return {}
		default:
			return state
	}
}

export const profileNotesReducer = (state = {}, action) => {
	switch (action.type) {
		case PROFILE_GET_NOTES_REQUEST:
			return { ...state, loadingNotes: true }
		case PROFILE_GET_NOTES_REQUEST_ADD:
			return { ...state, loadingAdd: action.payload }
		case PROFILE_GET_NOTES_SUCCESS:
			return { ...state, loadingNotes: false, currentNotes: action.payload, success: true }
		case PROFILE_GET_NOTES_SUCCESS_ADDED:
			return {
				...state,
				loadingNotes: false,
				successAdded: action.payload,
			}
		case PROFILE_GET_NOTES_FAIL:
			return { ...state, loadingNotes: false, error: action.payload }
		case PROFILE_GET_NOTES_FAIL_RESET:
			return { ...state, loadingNotes: false, loadingAdd: false, error: null }
		case PROFILE_GET_NOTES_RESET:
			return {}
		default:
			return state
	}
}

export const userNotesReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_GET_NOTES_REQUEST:
			return { ...state, loadingNotes: true }
		case USER_GET_NOTES_REQUEST_ADD:
			return { ...state, loadingAdd: action.payload }
		case USER_GET_NOTES_SUCCESS:
			return { ...state, loadingNotes: false, currentNotes: action.payload, success: true }
		case USER_GET_NOTES_SUCCESS_ADDED:
			return {
				...state,
				loadingNotes: false,
				successAdded: action.payload,
			}
		case USER_GET_NOTES_FAIL:
			return { ...state, loadingNotes: false, error: action.payload }
		case USER_GET_NOTES_FAIL_RESET:
			return { ...state, loadingNotes: false, loadingAdd: false, error: null }
		case USER_GET_NOTES_RESET:
			return {}
		default:
			return state
	}
}
