import {
	FINISHED_PROJECT_REGISTER_REQUEST,
	FINISHED_PROJECT_REGISTER_SUCCESS,
	FINISHED_PROJECT_REGISTER_FAIL,
	FINISHED_PROJECT_REGISTER_RESET,
	FINISHED_PROJECT_LIST_REQUEST,
	FINISHED_PROJECT_LIST_SUCCESS,
	FINISHED_PROJECT_LIST_FAIL,
	FINISHED_PROJECT_LIST_RESET,
} from '../constants/finishedProjectConstants'

export const finishedProjectRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case FINISHED_PROJECT_REGISTER_REQUEST:
			return { loadingFinishedProjectRegister: true }
		case FINISHED_PROJECT_REGISTER_SUCCESS:
			return {
				successFinishedProjectRegister: true,
				loadingFinishedProjectRegister: false,
				finishedProjectRegisterData: action.payload,
			}
		case FINISHED_PROJECT_REGISTER_FAIL:
			return {
				loadingFinishedProjectRegister: false,
				errorFinishedProjectRegister: action.payload,
			}
		case FINISHED_PROJECT_REGISTER_RESET:
			return {}
		default:
			return state
	}
}
export const finishedProjectListReducer = (state = {}, action) => {
	switch (action.type) {
		case FINISHED_PROJECT_LIST_REQUEST:
			return { loadingFinishedProjectList: true }
		case FINISHED_PROJECT_LIST_SUCCESS:
			return {
				successFinishedProjectList: true,
				loadingFinishedProjectList: false,
				finishedProjectListData: action.payload,
			}
		case FINISHED_PROJECT_LIST_FAIL:
			return {
				loadingFinishedProjectList: false,
				errorFinishedProjectList: action.payload,
			}
		case FINISHED_PROJECT_LIST_RESET:
			return {}
		default:
			return state
	}
}
