import axios from 'axios'
import {
  BACKUP_LIST_REQUEST,
  BACKUP_LIST_SUCCESS,
  BACKUP_LIST_FAIL,
  BACKUP_DOWNLOAD_DIR_REQUEST,
  BACKUP_DOWNLOAD_DIR_SUCCESS,
  BACKUP_DOWNLOAD_DIR_FAIL,
} from '../constants/backupConstants'

export const getBackups = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BACKUP_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/backups', config)

    dispatch({ type: BACKUP_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BACKUP_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
export const downloadBackupDir = (download) => async (dispatch, getState) => {
  try {
    dispatch({ type: BACKUP_DOWNLOAD_DIR_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/backups/${download.filter}?dir=${download.dir}`, config)

    dispatch({ type: BACKUP_DOWNLOAD_DIR_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BACKUP_DOWNLOAD_DIR_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
