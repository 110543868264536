import axios from 'axios'
import {
	NOTIFICATION_LIST_REQUEST,
	NOTIFICATION_LIST_SUCCESS,
	NOTIFICATION_LIST_FAIL,
	NOTIFICATION_BY_ID_REQUEST,
	NOTIFICATION_BY_ID_SUCCESS,
	NOTIFICATION_BY_ID_FAIL,
	NOTIFICATION_BY_USER_REQUEST,
	NOTIFICATION_BY_USER_SUCCESS,
	NOTIFICATION_BY_USER_FAIL,
	NOTIFICATION_REGISTER_REQUEST,
	NOTIFICATION_REGISTER_SUCCESS,
	NOTIFICATION_REGISTER_FAIL,
	NOTIFICATION_BY_TYPE_LIST_REQUEST,
	NOTIFICATION_BY_TYPE_LIST_SUCCESS,
	NOTIFICATION_BY_TYPE_LIST_FAIL,
} from '../constants/notificationConstants'

export const getNotifications = () => async (dispatch, getState) => {
	try {
		dispatch({ type: NOTIFICATION_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/notifications', config)

		dispatch({ type: NOTIFICATION_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: NOTIFICATION_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getNotificationById = (_id) => async (dispatch, getState) => {
	try {
		dispatch({ type: NOTIFICATION_BY_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/notifications/${_id}`, config)

		dispatch({ type: NOTIFICATION_BY_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: NOTIFICATION_BY_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getNotificationByUser = (userId) => async (dispatch, getState) => {
	try {
		dispatch({ type: NOTIFICATION_BY_USER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/notifications/users/${userId}`, config)

		dispatch({ type: NOTIFICATION_BY_USER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: NOTIFICATION_BY_USER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const registerNotification = (notificationData) => async (dispatch, getState) => {
	try {
		dispatch({ type: NOTIFICATION_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post(`/api/notifications`, notificationData, config)

		dispatch({ type: NOTIFICATION_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: NOTIFICATION_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getNotificationsList = () => async (dispatch, getState) => {
	try {
		dispatch({ type: NOTIFICATION_BY_TYPE_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/notifications/notifications-by-type`, config)

		dispatch({ type: NOTIFICATION_BY_TYPE_LIST_SUCCESS, payload: data.data })
	} catch (error) {
		dispatch({
			type: NOTIFICATION_BY_TYPE_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
