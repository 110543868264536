import { combineReducers } from 'redux'
import {
	profileNotesReducer,
	refreshTokenReducer,
	userConfirmPasswordReducer,
	userDeleteReducer,
	userDetailsReducer,
	userListReducer,
	userLoginReducer,
	userLoginWithMFAReducer,
	userDisableMFAReducer,
	userMFACodeValidateReducer,
	userNotesReducer,
	userRecoveryPasswordReducer,
	userRegisterReducer,
	userUpdateMyInfoReducer,
	userUpdateReducer,
} from './userReducers'
import {
	logByTypeReducer,
	logDeleteManyReducer,
	logDeleteReducer,
	logDetailsReducer,
	logListReducer,
	logProjectSeeInfoReducer,
	logRegisterReducer,
	logSearchReducer,
	logUsersLastLoginReducer,
} from './logReducers'
import { sendEMailReducer } from './sendEMailReducers'
import {
	investorGetUsersReducer,
	investorPrescriberProfilesReducer,
	investorRegisterReducer,
	investorUpdateReducer,
} from './investorReducer'
import {
	investorProfileByAdminRegisterReducer,
	investorProfileByIdReducer,
	investorProfileDeleteDocumentReducer,
	investorProfileDeleteReducer,
	investorProfileRegisterReducer,
	investorProfileUpdateReducer,
	investorProfileUploadDocumentsReducer,
	investorProfileWithUserRegisterReducer,
	investorsProfileByUserReducer,
	investorsProfileListForPDLReducer,
	investorsProfileListReducer,
	investorsProfilePaginationListReducer,
} from './investorProfileReducer'

import {
	getAcceptedProjectOfferReducer,
	projectByIdReducer,
	projectDeleteReducer,
	projectFinishedListReducer,
	projectListReducer,
	projectListToInvestReducer,
	projectRebuildCsvReducer,
	projectRegisterReducer,
	projectsDelayedListReducer,
	projectsFromSalesReducer,
	projectsToPdlLiquidateListReducer,
	projectsUpdateFinishedDateReducer,
	projectsWithPDLToInvestReducer,
	projectUpdateReducer,
	sendProjectSettlementEmailReducer,
	sigProjectUpdatebyPdlReducer,
	reasonsFromProjectByProjectIdListReducer,
} from './projectReducers'

import { finishedProjectListReducer, finishedProjectRegisterReducer } from './finishedProjectReducer'

import { endOfProjectDocumentRegisterReducer, endOfProjectDocumentDeleteReducer } from './endOfProjectDocumentReducer'

import {
	contractsInfoToDownloadReducer,
	investmenSignContractReducer,
	investmensLastListReducer,
	investmentByIdReducer,
	investmentDeclineBySuperAdminReducer,
	investmentListReducer,
	investmentProfileListReducer,
	investmentRegisterReducer,
	investmentsByDateAndInvestorReducer,
	investmentsByProfileAndProjectReducer,
	investmentsByProfileReducer,
	investmentsByProjectReducer,
	investmentsByStatusAndProfileReducer,
	investmentsByUserReducer,
	investmentsUpdatePrescriptionLiquidatedReducer,
	investmenUploadContractReducer,
	investNotesReducer,
	liquidatedInvestmentListReducer,
	prescriptorContractReducer,
	reInvestmentRegisterReducer,
	retentionCertificateByInvestmentIdReducer,
	unfinishedInvestmentsListReducer,
	updateInvestmentStatusReducer,
} from './investmentReducers'

import { incomeUpdateReducer, incomeDeleteDateReducer } from './incomeReducer'

import { investmentTreeListByUserReducer, investmentTreeByIdReducer } from './investmentTreeReducers'
import { eraserHeadProjectRegisterReducer, eraserHeadProjectUpdateReducer } from './eraserHeadReducers'
import {
	retentionDeleteReducer,
	retentionListReducer,
	retentionRegisterReducer,
	retentionUpdateReducer,
} from './retentionReducers'
import {
	prescriberListReducer,
	prescriberProfileListByIdReducer,
	prescriberRefererListByIdReducer,
} from './prescriberReducers'
import { backupListReducer, backupDownloadDirReducer } from './backupReducer'
import { kpisListReducer } from './kpisReducer'
import {
	preRegisterByEmailReducer,
	preRegisterDeleteReducer,
	preRegisterListReducer,
	preRegisterRegisterReducer,
	preRegisterUpdateReducer,
	preRegisterUserReducer,
} from './preRegisterReducers'

import {
	notificationByIdReducer,
	notificationByUserReducer,
	notificationListReducer,
	notificationRegisterReducer,
	notificationsDelayedReducer,
} from './notificationReducers'

import {
	ownerProfileByIdReducer,
	ownerProfileByUserReducer,
	ownerProfileDeleteReducer,
	ownerProfileWithUserRegisterReducer,
} from './ownerProfileReducer'
import { ownersGetUsersReducer } from './ownerReducer'
import { tableReducer } from './tableReducer'
import {
	contractsInfoToDownloadPDLReducer,
	deleteProjectLiquidityReducer,
	pdlRegisterByInvestmentReducer,
	projectLiquidityLiquidatedReducer,
	projectLiquidityLiquidateReducer,
	projectLiquidityListReducer,
	projectLiquidityRegisterReducer,
	projectLiquidityUpdateReducer,
	updatePdlDraftFieldsReducer,
	pdlRegisterReinvestmentReducer,
	registerReinvestPdlInvestmentsReducer,
	rebuildProjectLiquidityReducer,
} from './projectLiquidityReducers'
import {
	fixedRateLoansDeleteByIdReducer,
	fixedRateLoansListReducer,
	fixedRateLoansRegisterReducer,
	fixedRateLoansUpdateByIdReducer,
} from './fixedRateLoansReducer'
import { investmentCommentRegisterReducer, investmentCommentsByInvestmentIdReducer } from './investmentCommentReducer'

export default combineReducers({
	acceptedOffer: getAcceptedProjectOfferReducer,
	backupDownloadDir: backupDownloadDirReducer,
	backupList: backupListReducer,
	contractsInfoToDownload: contractsInfoToDownloadReducer,
	contractsInfoToDownloadPDL: contractsInfoToDownloadPDLReducer,
	deleteProjectLiquidity: deleteProjectLiquidityReducer,
	endOfProjectDocumentDelete: endOfProjectDocumentDeleteReducer,
	endOfProjectDocumentRegister: endOfProjectDocumentRegisterReducer,
	eraserHeadProjectRegister: eraserHeadProjectRegisterReducer,
	eraserHeadProjectUpdate: eraserHeadProjectUpdateReducer,
	finishedProjectList: finishedProjectListReducer,
	finishedProjectRegister: finishedProjectRegisterReducer,
	fixedRateLoansDeleteById: fixedRateLoansDeleteByIdReducer,
	fixedRateLoansList: fixedRateLoansListReducer,
	fixedRateLoansRegister: fixedRateLoansRegisterReducer,
	fixedRateLoansUpdateById: fixedRateLoansUpdateByIdReducer,
	incomeDeleteDate: incomeDeleteDateReducer,
	incomeUpdate: incomeUpdateReducer,
	investmenSignContract: investmenSignContractReducer,
	investmensLastList: investmensLastListReducer,
	investmentById: investmentByIdReducer,
	investmentCommentRegister: investmentCommentRegisterReducer,
	investmentCommentsByInvestmentId: investmentCommentsByInvestmentIdReducer,
	investmentDeclineBySuperAdmin: investmentDeclineBySuperAdminReducer,
	investmentProfileList: investmentProfileListReducer,
	investmentRegister: investmentRegisterReducer,
	investmentsByDateAndInvestor: investmentsByDateAndInvestorReducer,
	investmentsByProfile: investmentsByProfileReducer,
	investmentsByProfileAndProject: investmentsByProfileAndProjectReducer,
	investmentsByProject: investmentsByProjectReducer,
	investmentsByStatusAndProfile: investmentsByStatusAndProfileReducer,
	investmentsByUser: investmentsByUserReducer,
	investmentsList: investmentListReducer,
	investmentsUpdatePrescriptionLiquidated: investmentsUpdatePrescriptionLiquidatedReducer,
	investmentTreeById: investmentTreeByIdReducer,
	investmentTreeListByUser: investmentTreeListByUserReducer,
	investmenUploadContract: investmenUploadContractReducer,
	investNotes: investNotesReducer,
	investorGetUsers: investorGetUsersReducer,
	investorPrescriberProfiles: investorPrescriberProfilesReducer,
	investorProfileByAdminRegister: investorProfileByAdminRegisterReducer,
	investorProfileById: investorProfileByIdReducer,
	investorProfileDelete: investorProfileDeleteReducer,
	investorProfileDeleteDocument: investorProfileDeleteDocumentReducer,
	investorProfileRegister: investorProfileRegisterReducer,
	investorProfileUpdate: investorProfileUpdateReducer,
	investorProfileUploadDocuments: investorProfileUploadDocumentsReducer,
	investorProfileWithUserRegister: investorProfileWithUserRegisterReducer,
	investorRegister: investorRegisterReducer,
	investorsProfileByUser: investorsProfileByUserReducer,
	investorsProfileList: investorsProfileListReducer,
	investorsProfileListForPDL: investorsProfileListForPDLReducer,
	investorsProfilePaginationList: investorsProfilePaginationListReducer,
	investorUpdate: investorUpdateReducer,
	kpisList: kpisListReducer,
	liquidatedInvestmentsList: liquidatedInvestmentListReducer,
	logByType: logByTypeReducer,
	logDelete: logDeleteReducer,
	logDeleteMany: logDeleteManyReducer,
	logDetails: logDetailsReducer,
	logList: logListReducer,
	logRegister: logRegisterReducer,
	logSearch: logSearchReducer,
	logSeeInfo: logProjectSeeInfoReducer,
	logUsersLastLogin: logUsersLastLoginReducer,
	notificationById: notificationByIdReducer,
	notificationByUser: notificationByUserReducer,
	notificationList: notificationListReducer,
	notificationRegister: notificationRegisterReducer,
	notificationsDelayed: notificationsDelayedReducer,
	ownerProfileByIdUser: ownerProfileByIdReducer,
	ownerProfileByUser: ownerProfileByUserReducer,
	ownerProfileDelete: ownerProfileDeleteReducer,
	ownerProfileWithUserRegister: ownerProfileWithUserRegisterReducer,
	ownersGetUsers: ownersGetUsersReducer,
	pdlRegisterByInvestment: pdlRegisterByInvestmentReducer,
	pdlRegisterReinvestPdlInvestments: registerReinvestPdlInvestmentsReducer,
	pdlRegisterReinvestment: pdlRegisterReinvestmentReducer,
	preRegisterByEmail: preRegisterByEmailReducer,
	preRegisterDelete: preRegisterDeleteReducer,
	preRegisterList: preRegisterListReducer,
	preRegisterRegister: preRegisterRegisterReducer,
	preRegisterUpdate: preRegisterUpdateReducer,
	preRegisterUser: preRegisterUserReducer,
	prescriberList: prescriberListReducer,
	prescriberProfileList: prescriberProfileListByIdReducer,
	prescriberRefererListById: prescriberRefererListByIdReducer,
	prescriptorContract: prescriptorContractReducer,
	profileNotes: profileNotesReducer,
	projectById: projectByIdReducer,
	projectDelete: projectDeleteReducer,
	projectFinishedList: projectFinishedListReducer,
	projectLiquidityLiquidate: projectLiquidityLiquidateReducer,
	projectLiquidityLiquidated: projectLiquidityLiquidatedReducer,
	projectLiquidityList: projectLiquidityListReducer,
	projectLiquidityRegister: projectLiquidityRegisterReducer,
	projectLiquidityUpdate: projectLiquidityUpdateReducer,
	projectList: projectListReducer,
	projectListToInvest: projectListToInvestReducer,
	projectRebuildCsv: projectRebuildCsvReducer,
	projectRegister: projectRegisterReducer,
	projectsDelayedList: projectsDelayedListReducer,
	projectsFromSales: projectsFromSalesReducer,
	projectsToPdlLiquidateList: projectsToPdlLiquidateListReducer,
	projectsUpdateFinishedDate: projectsUpdateFinishedDateReducer,
	projectsWithPDLToInvest: projectsWithPDLToInvestReducer,
	projectUpdate: projectUpdateReducer,
	reasonsFromProjectByProjectIdList: reasonsFromProjectByProjectIdListReducer,
	rebuildProjectLiquidity: rebuildProjectLiquidityReducer,
	refreshToken: refreshTokenReducer,
	userConfirmPassword: userConfirmPasswordReducer,
	reInvestmentRegister: reInvestmentRegisterReducer,
	retentionCertificateByInvestmentId: retentionCertificateByInvestmentIdReducer,
	retentionDelete: retentionDeleteReducer,
	retentionList: retentionListReducer,
	retentionRegister: retentionRegisterReducer,
	retentionUpdate: retentionUpdateReducer,
	sendEMail: sendEMailReducer,
	sendProjectSettlementEmail: sendProjectSettlementEmailReducer,
	sigProjectUpdatebyPdl: sigProjectUpdatebyPdlReducer,
	tableParams: tableReducer,
	unfinishedInvestmentsList: unfinishedInvestmentsListReducer,
	updateInvestmentStatus: updateInvestmentStatusReducer,
	updatePdlDraftFields: updatePdlDraftFieldsReducer,
	userDelete: userDeleteReducer,
	userDetails: userDetailsReducer,
	userList: userListReducer,
	userLogin: userLoginReducer,
	userLoginWithMFA: userLoginWithMFAReducer,
	userDisableMFA: userDisableMFAReducer,
	userMFACodeValidate: userMFACodeValidateReducer,
	userNotes: userNotesReducer,
	userRecoveryPassword: userRecoveryPasswordReducer,
	userRegister: userRegisterReducer,
	userUpdate: userUpdateReducer,
	userUpdateMyInfo: userUpdateMyInfoReducer,
})
