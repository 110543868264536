import axios from 'axios'
import {
	OWNER_PROFILE_WITH_USER_REGISTER_REQUEST,
	OWNER_PROFILE_WITH_USER_REGISTER_SUCCESS,
	OWNER_PROFILE_WITH_USER_REGISTER_FAIL,
	OWNER_PROFILE_BY_ID_REQUEST,
	OWNER_PROFILE_BY_ID_SUCCESS,
	OWNER_PROFILE_BY_ID_FAIL,
	OWNER_PROFILE_DELETE_REQUEST,
	OWNER_PROFILE_DELETE_SUCCESS,
	OWNER_PROFILE_DELETE_FAIL,
} from 'local_redux/constants/ownersProfileConstants'

export const registerProfileOwnerWithUser = (profileOwnerData) => async (dispatch, getState) => {
	try {
		dispatch({ type: OWNER_PROFILE_WITH_USER_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const fieldsFiles = ['residenceCertificate', 'profileDniFront', 'profileDniBack', 'kycContract']

		let formData = new FormData()

		for (let key in profileOwnerData) {
			if (!fieldsFiles.includes(key)) {
				formData.append(key, profileOwnerData[key])
			}
		}
		if (typeof profileOwnerData.residenceCertificate === 'object') {
			formData.append('residenceCertificate', profileOwnerData.residenceCertificate)
		}
		if (typeof profileOwnerData.profileDniFront === 'object') {
			formData.append('profileDniFront', profileOwnerData.profileDniFront)
		}
		if (typeof profileOwnerData.profileDniBack === 'object') {
			formData.append('profileDniBack', profileOwnerData.profileDniBack)
		}
		if (typeof profileOwnerData.kycContract === 'object') {
			formData.append('kycContract', profileOwnerData.kycContract)
		}
		if (typeof profileOwnerData.accountOwnership === 'object') {
			formData.append('accountOwnership', profileOwnerData.accountOwnership)
		}

		const { data } = await axios.post('/api/owners', formData, config)

		dispatch({ type: OWNER_PROFILE_WITH_USER_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: OWNER_PROFILE_WITH_USER_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getOwnersProfileListByIdUser = (userId) => async (dispatch, getState) => {
	try {
		dispatch({ type: OWNER_PROFILE_BY_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Cache-Control': 'no-cache',
			},
		}

		const { data } = await axios.get(`/api/profiles/${userId}`, config)

		dispatch({ type: OWNER_PROFILE_BY_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: OWNER_PROFILE_BY_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteOwnerProfile = (profileInfo) => async (dispatch, getState) => {
	try {
		dispatch({ type: OWNER_PROFILE_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.delete(`/api/profiles/${profileInfo._id}`, config)

		data.id = profileInfo._id

		dispatch({ type: OWNER_PROFILE_DELETE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: OWNER_PROFILE_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
