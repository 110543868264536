import * as types from '../constants/tableConstants'

export const tableReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LOADING_TABLE_PARAMS:
			return { ...state, loadingTableParams: action.payload }
		case types.PAGE_CHANGE:
			return { ...state, page: action.payload }
		case types.PAGE_SIZE_CHANGE:
			return { ...state, pageSize: action.payload }
		case types.ALL_PAGES_CHANGE:
			return { ...state, allPages: action.payload }
		case types.TABLE_UPDATE_FILTER:
			return {
				...state,
				filters: {
					...(state.filters ?? {}),
					[action.payload.key]: action.payload.value,
				},
			}
		case types.TABLE_REDUCER_RESET_FILTERS:
			return { ...state, filters: null }
		case types.TABLE_REDUCER_RESET:
			return {}
		default:
			return state
	}
}
