import axios from 'axios'
import * as types from 'local_redux/constants/projectLiquidityConstants'

export const registerProjectLiquidity = (projectLiquidity) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.PROJECT_LIQUIDITY_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/project-liquidity', projectLiquidity, config)

		dispatch({ type: types.PROJECT_LIQUIDITY_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.PROJECT_LIQUIDITY_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateProjectLiquidation = (projectLiquidity, updateProjects) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.PROJECT_LIQUIDITY_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		// Make the PUT request to update the liquidation field
		const { data } = await axios.put('/api/project-liquidity/change-liquidate', projectLiquidity, config)

		console.log('Success on updating liquidation to Finally Pay')
		updateProjects(projectLiquidity)

		dispatch({ type: types.PROJECT_LIQUIDITY_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		console.error(error.response && error.response.data.message ? error.response.data.message : error.message)
		dispatch({
			type: types.PROJECT_LIQUIDITY_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const liquidateProjectLiquidity = (body) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.PROJECT_LIQUIDITY_LIQUIDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/project-liquidity/liquidate', body, config)

		dispatch({ type: types.PROJECT_LIQUIDITY_LIQUIDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.PROJECT_LIQUIDITY_LIQUIDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getProjectsLiquiditiesLiquidated =
	(query = '') =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: types.PROJECT_LIQUIDITY_LIQUIDATED_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			const { data } = await axios.get(`/api/project-liquidity/liquidated${query}`, config)

			dispatch({ type: types.PROJECT_LIQUIDITY_LIQUIDATED_SUCCESS, payload: data })
		} catch (error) {
			dispatch({
				type: types.PROJECT_LIQUIDITY_LIQUIDATED_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}

export const getProjectLiquidityList = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.PROJECT_LIQUIDITY_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/project-liquidity', config)

		dispatch({ type: types.PROJECT_LIQUIDITY_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.PROJECT_LIQUIDITY_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateProjectLiquidity = (id, dataToSend) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.PROJECT_LIQUIDITY_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/project-liquidity/fields/${id}`, dataToSend, config)

		dispatch({ type: types.PROJECT_LIQUIDITY_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.PROJECT_LIQUIDITY_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updatePdlsDraftFields = (body) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.PROJECT_LIQUIDITY_DRAFT_UPDATE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/project-liquidity/draft/${body._id}`, body, config)

		dispatch({ type: types.PROJECT_LIQUIDITY_DRAFT_UPDATE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.PROJECT_LIQUIDITY_DRAFT_UPDATE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const registerPdlsByInvestment = (pdlReInvestmentData) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.PDL_REGISTER_BY_INVESTMENT_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/project-liquidity/re-investment', pdlReInvestmentData, config)

		dispatch({ type: types.PDL_REGISTER_BY_INVESTMENT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.PDL_REGISTER_BY_INVESTMENT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteProjectLiquidity = (id, body) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.PROJECT_LIQUIDITY_DELETE_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/project-liquidity/delete/${id}`, body, config)

		dispatch({ type: types.PROJECT_LIQUIDITY_DELETE_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.PROJECT_LIQUIDITY_DELETE_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const registerReinvestPdl = (pdlReInvestmentData) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.PDL_REGISTER_REINVESTMENT_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/project-liquidity/reinvest-pdl', pdlReInvestmentData, config)

		dispatch({ type: types.PDL_REGISTER_REINVESTMENT_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.PDL_REGISTER_REINVESTMENT_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const registerReinvestPdlInvestments = (InvestmentData) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.PDL_REGISTER_REINVEST_INVESTMENTS_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/project-liquidity/reinvest-pdl-investments', InvestmentData, config)

		dispatch({ type: types.PDL_REGISTER_REINVEST_INVESTMENTS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.PDL_REGISTER_REINVEST_INVESTMENTS_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getContractsInfoToDownloadPDL =
	({ page, date, projectKey, investorKey }) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: types.GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_REQUEST })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}
			const { data } = await axios.get(
				`/api/project-liquidity/contract-info?${page ? '&page=' + page : ''}${
					date.end ? `&start=${date.start}&end=${date.end}` : ''
				}${projectKey ? `&projectKey=${projectKey}` : ''}${investorKey ? `&investorKey=${investorKey}` : ''}`,
				config,
			)

			dispatch({ type: types.GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_SUCCESS, payload: data })
		} catch (error) {
			dispatch({
				type: types.GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_FAIL,
				payload: error.response && error.response.data.message ? error.response.data.message : error.message,
			})
		}
	}
export const rebuildProjectLiquidityCsv = (body) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.REBUILD_PROJECT_LIQUIDITY_CSV_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const { data } = await axios.post(`/api/project-liquidity/rebuild-csv/`, body, config)

		dispatch({ type: types.REBUILD_PROJECT_LIQUIDITY_CSV_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: types.REBUILD_PROJECT_LIQUIDITY_CSV_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
