export const PRE_REGISTER_REGISTER_REQUEST = 'PRE_REGISTER_REGISTER_REQUEST'
export const PRE_REGISTER_REGISTER_SUCCESS = 'PRE_REGISTER_REGISTER_SUCCESS'
export const PRE_REGISTER_REGISTER_FAIL = 'PRE_REGISTER_REGISTER_FAIL'
export const PRE_REGISTER_REGISTER_RESET = 'PRE_REGISTER_REGISTER_RESET'

export const PRE_REGISTER_BY_EMAIL_REQUEST = 'PRE_REGISTER_BY_EMAIL_REQUEST'
export const PRE_REGISTER_BY_EMAIL_SUCCESS = 'PRE_REGISTER_BY_EMAIL_SUCCESS'
export const PRE_REGISTER_BY_EMAIL_FAIL = 'PRE_REGISTER_BY_EMAIL_FAIL'
export const PRE_REGISTER_BY_EMAIL_RESET = 'PRE_REGISTER_BY_EMAIL_RESET'

export const PRE_REGISTER_USER_REQUEST = 'PRE_REGISTER_USER_REQUEST'
export const PRE_REGISTER_USER_SUCCESS = 'PRE_REGISTER_USER_SUCCESS'
export const PRE_REGISTER_USER_FAIL = 'PRE_REGISTER_USER_FAIL'
export const PRE_REGISTER_USER_RESET = 'PRE_REGISTER_USER_RESET'

export const PRE_REGISTER_LIST_REQUEST = 'PRE_REGISTER_LIST_REQUEST'
export const PRE_REGISTER_LIST_SUCCESS = 'PRE_REGISTER_LIST_SUCCESS'
export const PRE_REGISTER_LIST_FAIL = 'PRE_REGISTER_LIST_FAIL'
export const PRE_REGISTER_LIST_RESET = 'PRE_REGISTER_LIST_RESET'

export const PRE_REGISTER_UPDATE_REQUEST = 'PRE_REGISTER_UPDATE_REQUEST'
export const PRE_REGISTER_UPDATE_SUCCESS = 'PRE_REGISTER_UPDATE_SUCCESS'
export const PRE_REGISTER_UPDATE_FAIL = 'PRE_REGISTER_UPDATE_FAIL'
export const PRE_REGISTER_UPDATE_RESET = 'PRE_REGISTER_UPDATE_RESET'

export const PRE_REGISTER_DELETE_REQUEST = 'PRE_REGISTER_DELETE_REQUEST'
export const PRE_REGISTER_DELETE_SUCCESS = 'PRE_REGISTER_DELETE_SUCCESS'
export const PRE_REGISTER_DELETE_FAIL = 'PRE_REGISTER_DELETE_FAIL'
export const PRE_REGISTER_DELETE_RESET = 'PRE_REGISTER_DELETE_RESET'
