import * as types from '../constants/investmentConstants'

export const investmentRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_REGISTER_REQUEST:
			return { loadingInvestmentRegister: true }
		case types.INVESTMENT_REGISTER_SUCCESS:
			return {
				investmentRegisterSuccess: true,
				loadingInvestmentRegister: false,
				investmentRegisterData: action.payload,
			}
		case types.INVESTMENT_REGISTER_FAIL:
			return {
				loadingInvestmentRegister: false,
				errorInvestmentRegister: action.payload,
			}
		case types.INVESTMENT_REGISTER_RESET:
			return {}
		default:
			return state
	}
}
export const reInvestmentRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.RE_INVESTMENT_REGISTER_REQUEST:
			return { loadingReinvestmentRegister: true }
		case types.RE_INVESTMENT_REGISTER_SUCCESS:
			return {
				reinvestmentRegisterSuccess: true,
				loadingReinvestmentRegister: false,
				reinvestmentRegisterData: action.payload,
			}
		case types.RE_INVESTMENT_REGISTER_FAIL:
			return {
				loadingReinvestmentRegister: false,
				errorReinvestmentRegister: action.payload,
			}
		case types.RE_INVESTMENT_REGISTER_RESET:
			return {}
		default:
			return state
	}
}
export const investmentsByProjectReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENTS_BY_PROJECT_REQUEST:
			return { loadingInvestmentsByProject: true }
		case types.INVESTMENTS_BY_PROJECT_SUCCESS:
			return {
				loadingInvestmentsByProject: false,
				successInvestmentsByProject: true,
				investmentsByProject: action.payload,
			}
		case types.INVESTMENTS_BY_PROJECT_FAIL:
			return {
				loadingInvestmentsByProject: false,
				errorInvestmentsByProject: action.payload,
			}
		case types.INVESTMENTS_BY_PROJECT_RESET:
			return {}
		default:
			return state
	}
}
export const investmentsByProfileAndProjectReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENTS_BY_PROFILE_AND_PROJECT_REQUEST:
			return { loadingInvestmentsByProfileAndProject: true }
		case types.INVESTMENTS_BY_PROFILE_AND_PROJECT_SUCCESS:
			return {
				loadingInvestmentsByProfileAndProject: false,
				successInvestmentsByProfileAndProject: true,
				investmentsByProfileAndProjectData: action.payload,
			}
		case types.INVESTMENTS_BY_PROFILE_AND_PROJECT_FAIL:
			return {
				loadingInvestmentsByProfileAndProject: false,
				errorInvestmentsByProfileAndProject: action.payload,
			}
		case types.INVESTMENTS_BY_PROFILE_AND_PROJECT_RESET:
			return {}
		default:
			return state
	}
}
export const investmentListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_LIST_REQUEST:
			return { loadingInvestmentsList: true }
		case types.INVESTMENT_LIST_SUCCESS:
			return {
				loadingInvestmentsList: false,
				successInvestmentsList: true,
				investmentsList: action.payload,
			}
		case types.INVESTMENT_LIST_FAIL:
			return {
				loadingInvestmentsList: false,
				errorInvestmentsList: action.payload,
			}
		case types.INVESTMENT_LIST_RESET:
			return {}
		default:
			return state
	}
}
export const unfinishedInvestmentsListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.UNFINISHED_INVESTMENT_LIST_REQUEST:
			return { loadingUnfinishedInvestmentsList: true }
		case types.UNFINISHED_INVESTMENT_LIST_SUCCESS:
			return {
				loadingUnfinishedInvestmentsList: false,
				successUnfinishedInvestmentsList: true,
				unfinishedInvestmentsListData: action.payload,
			}
		case types.UNFINISHED_INVESTMENT_LIST_FAIL:
			return {
				loadingUnfinishedInvestmentsList: false,
				errorUnfinishedInvestmentsList: action.payload,
			}
		case types.UNFINISHED_INVESTMENT_LIST_RESET:
			return {}
		default:
			return state
	}
}
export const investmentsByStatusAndProfileReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENTS_BY_STATUS_AND_PROFILE_REQUEST:
			return { loadingInvestmentsList: true }
		case types.INVESTMENTS_BY_STATUS_AND_PROFILE_SUCCESS:
			return {
				loadingInvestmentsList: false,
				successInvestmentsList: true,
				investmentsList: action.payload,
			}
		case types.INVESTMENTS_BY_STATUS_AND_PROFILE_FAIL:
			return {
				loadingInvestmentsList: false,
				errorInvestmentsList: action.payload,
			}
		case types.INVESTMENTS_BY_STATUS_AND_PROFILE_RESET:
			return {}
		default:
			return state
	}
}
export const liquidatedInvestmentListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LIQUIDATED_INVESTMENT_LIST_REQUEST:
			return { loadingLiquidatedInvestmentsList: true }
		case types.LIQUIDATED_INVESTMENT_LIST_SUCCESS:
			return {
				loadingLiquidatedInvestmentsList: false,
				successLiquidatedInvestmentsList: true,
				liquidatedInvestmentsListData: action.payload,
			}
		case types.LIQUIDATED_INVESTMENT_LIST_FAIL:
			return {
				loadingLiquidatedInvestmentsList: false,
				errorLiquidatedInvestmentsList: action.payload,
			}
		case types.LIQUIDATED_INVESTMENT_LIST_RESET:
			return {}
		default:
			return state
	}
}
export const investmentProfileListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_PROFILE_LIST_REQUEST:
			return { loadingInvestmentsProfileList: true }
		case types.INVESTMENT_PROFILE_LIST_SUCCESS:
			return {
				loadingInvestmentsProfileList: false,
				successInvestmentsProfileList: true,
				investmentsProfileList: action.payload,
			}
		case types.INVESTMENT_PROFILE_LIST_FAIL:
			return {
				loadingInvestmentsProfileList: false,
				errorInvestmentsProfileList: action.payload,
			}
		case types.INVESTMENT_PROFILE_LIST_RESET:
			return {}
		default:
			return state
	}
}
export const investmentDeclineBySuperAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_ADMIN_DECLINE_REQUEST:
			return { loadingInvestmentDeclineBySuperAdmin: true }
		case types.INVESTMENT_ADMIN_DECLINE_SUCCESS:
			return {
				successInvestmentDeclineBySuperAdmin: true,
				loadingInvestmentDeclineBySuperAdmin: false,
				investmentDeclineBySuperAdminData: action.payload,
			}
		case types.INVESTMENT_ADMIN_DECLINE_FAIL:
			return {
				loadingInvestmentDeclineBySuperAdmin: false,
				errorInvestmentDeclineBySuperAdmin: action.payload,
			}
		case types.INVESTMENT_ADMIN_DECLINE_RESET:
			return {}
		default:
			return state
	}
}
export const updateInvestmentStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case types.UPDATE_INVESTMENT_STATUS_REQUEST:
			return { loadingUpdateInvestmentStatus: true }
		case types.UPDATE_INVESTMENT_STATUS_SUCCESS:
			return {
				successUpdateInvestmentStatus: true,
				loadingUpdateInvestmentStatus: false,
				updateInvestmentStatusData: action.payload,
			}
		case types.UPDATE_INVESTMENT_STATUS_FAIL:
			return {
				loadingUpdateInvestmentStatus: false,
				errorUpdateInvestmentStatus: action.payload,
			}
		case types.UPDATE_INVESTMENT_STATUS_RESET:
			return {}
		default:
			return state
	}
}
export const investmentByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_BY_ID_REQUEST:
			return { loadingInvestmentById: true }
		case types.INVESTMENT_BY_ID_SUCCESS:
			return {
				successInvestmentById: true,
				loadingInvestmentById: false,
				investmentByIdData: action.payload,
			}
		case types.INVESTMENT_BY_ID_FAIL:
			return {
				loadingInvestmentById: false,
				errorInvestmentById: action.payload,
			}
		case types.INVESTMENT_BY_ID_RESET:
			return {}
		default:
			return state
	}
}
export const investmenUploadContractReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_UPLOAD_CONTRACT_REQUEST:
			return { loadingInvestmenUploadContract: true }
		case types.INVESTMENT_UPLOAD_CONTRACT_SUCCESS:
			return {
				successInvestmenUploadContract: true,
				loadingInvestmenUploadContract: false,
				investmenUploadContractData: action.payload,
			}
		case types.INVESTMENT_UPLOAD_CONTRACT_FAIL:
			return {
				loadingInvestmenUploadContract: false,
				errorInvestmenUploadContract: action.payload,
			}
		case types.INVESTMENT_UPLOAD_CONTRACT_RESET:
			return {}
		default:
			return state
	}
}
export const investmenSignContractReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_SIGN_CONTRACT_REQUEST:
			return { loadingInvestmenSignContract: true }
		case types.INVESTMENT_SIGN_CONTRACT_SUCCESS:
			return {
				successInvestmenSignContract: true,
				loadingInvestmenSignContract: false,
				investmenSignContractData: action.payload,
			}
		case types.INVESTMENT_SIGN_CONTRACT_FAIL:
			return {
				loadingInvestmenSignContract: false,
				errorInvestmenSignContract: action.payload,
			}
		case types.INVESTMENT_SIGN_CONTRACT_RESET:
			return {}
		default:
			return state
	}
}
export const investmensLastListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_LAST_LIST_REQUEST:
			return { loadingInvestmenLastProfile: true }
		case types.INVESTMENT_LAST_LIST_SUCCESS:
			return {
				successInvestmenLastProfile: true,
				loadingInvestmenLastProfile: false,
				investmenLastProfileData: action.payload,
			}
		case types.INVESTMENT_LAST_LIST_FAIL:
			return {
				loadingInvestmenLastProfile: false,
				errorInvestmenLastProfile: action.payload,
			}
		case types.INVESTMENT_LAST_LIST_RESET:
			return {}
		default:
			return state
	}
}
export const investmentsByProfileReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_BY_PROFILE_LIST_REQUEST:
			return { loadingInvestmentsByProfile: true }
		case types.INVESTMENT_BY_PROFILE_LIST_SUCCESS:
			return {
				successInvestmentsByProfile: true,
				loadingInvestmentsByProfile: false,
				investmentsByProfileData: action.payload,
			}
		case types.INVESTMENT_BY_PROFILE_LIST_FAIL:
			return {
				loadingInvestmentsByProfile: false,
				errorInvestmentsByProfile: action.payload,
			}
		case types.INVESTMENT_BY_PROFILE_LIST_RESET:
			return {}
		default:
			return state
	}
}
export const investmentsByUserReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_BY_USER_LIST_REQUEST:
			return { loadingInvestmentsByUser: true }
		case types.INVESTMENT_BY_USER_LIST_SUCCESS:
			return {
				successInvestmentsByUser: true,
				loadingInvestmentsByUser: false,
				investmentsByUserData: action.payload,
			}
		case types.INVESTMENT_BY_USER_LIST_FAIL:
			return {
				loadingInvestmentsByUser: false,
				errorInvestmentsByUser: action.payload,
			}
		case types.INVESTMENT_BY_USER_LIST_RESET:
			return {}
		default:
			return state
	}
}
export const investmentsUpdatePrescriptionLiquidatedReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_REQUEST:
			return { loadingInvestmentsUpdatePrescriptionLiquidated: true }
		case types.INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_SUCCESS:
			return {
				successInvestmentsUpdatePrescriptionLiquidated: true,
				loadingInvestmentsUpdatePrescriptionLiquidated: false,
				investmentsUpdatePrescriptionLiquidatedData: action.payload,
			}
		case types.INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_FAIL:
			return {
				loadingInvestmentsUpdatePrescriptionLiquidated: false,
				errorInvestmentsUpdatePrescriptionLiquidated: action.payload,
			}
		case types.INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_RESET:
			return {}
		default:
			return state
	}
}
export const investmentsByDateAndInvestorReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_BY_DATE_AND_INVESTOR_REQUEST:
			return { loadingInvestmentsByDateAndInvestor: true }
		case types.INVESTMENT_BY_DATE_AND_INVESTOR_SUCCESS:
			return {
				loadingInvestmentsByDateAndInvestor: false,
				successInvestmentsByDateAndInvestor: true,
				investmentsByDateAndInvestor: action.payload,
			}
		case types.INVESTMENT_BY_DATE_AND_INVESTOR_FAIL:
			return {
				loadingInvestmentsByDateAndInvestor: false,
				errorInvestmentsByDateAndInvestor: action.payload,
			}
		case types.INVESTMENT_BY_DATE_AND_INVESTOR_RESET:
			return {}
		default:
			return state
	}
}

export const investNotesReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTS_GET_NOTES_REQUEST:
			return { ...state, loadingNotes: true }
		case types.INVESTS_GET_NOTES_REQUEST_ADD:
			return { ...state, loadingAdd: action.payload }
		case types.INVESTS_GET_NOTES_SUCCESS:
			return { ...state, loadingNotes: false, currentNotes: action.payload, success: true }
		case types.INVESTS_GET_NOTES_SUCCESS_ADDED:
			return {
				...state,
				loadingNotes: false,
				successAdded: action.payload,
			}
		case types.INVESTS_GET_NOTES_FAIL:
			return { ...state, loadingNotes: false, error: action.payload }
		case types.INVESTS_GET_NOTES_FAIL_RESET:
			return { ...state, loadingNotes: false, loadingAdd: false, error: null }
		case types.INVESTS_GET_NOTES_RESET:
			return {}
		default:
			return state
	}
}

export const retentionCertificateByInvestmentIdReducer = (state = {}, action) => {
	switch (action.type) {
		case types.GET_RETENTION_CERTIFICATE_BY_INVESTMENT_REQUEST:
			return { loadingRetentionCertificateByInvestmentId: true }
		case types.GET_RETENTION_CERTIFICATE_BY_INVESTMENT_SUCCESS:
			return {
				loadingRetentionCertificateByInvestmentId: false,
				successRetentionCertificateByInvestmentId: true,
				retentionCertificate: action.payload,
			}
		case types.GET_RETENTION_CERTIFICATE_BY_INVESTMENT_FAIL:
			return {
				loadingRetentionCertificateByInvestmentId: false,
				errorRetentionCertificateByInvestmentId: action.payload,
			}
		case types.GET_RETENTION_CERTIFICATE_BY_INVESTMENT_RESET:
			return {}
		default:
			return state
	}
}

export const contractsInfoToDownloadReducer = (state = {}, action) => {
	switch (action.type) {
		case types.GET_CONTRACTS_INFO_TO_DOWNLOAD_REQUEST:
			return { loadingContractsInfoToDownload: true }
		case types.GET_CONTRACTS_INFO_TO_DOWNLOAD_SUCCESS:
			return {
				loadingContractsInfoToDownload: false,
				successContractsInfoToDownload: true,
				contractsInfoToDownload: action.payload,
			}
		case types.GET_CONTRACTS_INFO_TO_DOWNLOAD_FAIL:
			return {
				loadingContractsInfoToDownload: false,
				errorContractsInfoToDownload: action.payload,
			}
		case types.GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET:
			return {}
		default:
			return state
	}
}

export const prescriptorContractReducer = (state = {}, action) => {
	switch (action.type) {
		case types.SEND_PRESCRIPTOR_CONTRACT_REQUEST:
			return { loadingPrescriptorContract: true }
		case types.SEND_PRESCRIPTOR_CONTRACT_SUCCESS:
			return {
				loadingPrescriptorContract: false,
				successPrescriptorContract: true,
				prescriptorContract: action.payload,
			}
		case types.SEND_PRESCRIPTOR_CONTRACT_FAIL:
			return {
				loadingPrescriptorContract: false,
				errorPrescriptorContract: action.payload,
			}
		case types.SEND_PRESCRIPTOR_CONTRACT_RESET:
			return {}
		default:
			return state
	}
}
