import axios from 'axios'
import {
  RETENTION_REGISTER_REQUEST,
  RETENTION_REGISTER_SUCCESS,
  RETENTION_REGISTER_FAIL,
  RETENTION_LIST_REQUEST,
  RETENTION_LIST_SUCCESS,
  RETENTION_LIST_FAIL,
  RETENTION_UPDATE_REQUEST,
  RETENTION_UPDATE_SUCCESS,
  RETENTION_UPDATE_FAIL,
  RETENTION_DELETE_REQUEST,
  RETENTION_DELETE_SUCCESS,
  RETENTION_DELETE_FAIL,
} from '../constants/retentionConstants'

export const registerRetention = (retentionData) => async (dispatch, getState) => {
  try {
    dispatch({ type: RETENTION_REGISTER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post('/api/retentions', retentionData, config)

    dispatch({ type: RETENTION_REGISTER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: RETENTION_REGISTER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
export const getRetentionList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: RETENTION_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/retentions', config)

    dispatch({ type: RETENTION_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: RETENTION_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
export const updateRetention = (retentionData) => async (dispatch, getState) => {
  try {
    dispatch({ type: RETENTION_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/retentions/${retentionData._id}`, retentionData, config)

    dispatch({ type: RETENTION_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: RETENTION_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
export const deleteRetention = (retentionId) => async (dispatch, getState) => {
  try {
    dispatch({ type: RETENTION_DELETE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(`/api/retentions/${retentionId}`, config)

    dispatch({ type: RETENTION_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: RETENTION_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
