export const INVESTMENT_REGISTER_REQUEST = 'INVESTMENT_REGISTER_REQUEST'
export const INVESTMENT_REGISTER_SUCCESS = 'INVESTMENT_REGISTER_SUCCESS'
export const INVESTMENT_REGISTER_FAIL = 'INVESTMENT_REGISTER_FAIL'
export const INVESTMENT_REGISTER_RESET = 'INVESTMENT_REGISTER_RESET'

export const RE_INVESTMENT_REGISTER_REQUEST = 'RE_INVESTMENT_REGISTER_REQUEST'
export const RE_INVESTMENT_REGISTER_SUCCESS = 'RE_INVESTMENT_REGISTER_SUCCESS'
export const RE_INVESTMENT_REGISTER_FAIL = 'RE_INVESTMENT_REGISTER_FAIL'
export const RE_INVESTMENT_REGISTER_RESET = 'RE_INVESTMENT_REGISTER_RESET'

export const UPDATE_INVESTMENT_STATUS_REQUEST = 'UPDATE_INVESTMENT_STATUS_REQUEST'
export const UPDATE_INVESTMENT_STATUS_SUCCESS = 'UPDATE_INVESTMENT_STATUS_SUCCESS'
export const UPDATE_INVESTMENT_STATUS_FAIL = 'UPDATE_INVESTMENT_STATUS_FAIL'
export const UPDATE_INVESTMENT_STATUS_RESET = 'UPDATE_INVESTMENT_STATUS_RESET'

export const INVESTMENT_ADMIN_DECLINE_REQUEST = 'INVESTMENT_ADMIN_DECLINE_REQUEST'
export const INVESTMENT_ADMIN_DECLINE_SUCCESS = 'INVESTMENT_ADMIN_DECLINE_SUCCESS'
export const INVESTMENT_ADMIN_DECLINE_FAIL = 'INVESTMENT_ADMIN_DECLINE_FAIL'
export const INVESTMENT_ADMIN_DECLINE_RESET = 'INVESTMENT_ADMIN_DECLINE_RESET'

export const INVESTMENTS_BY_PROFILE_AND_PROJECT_REQUEST = 'INVESTMENTS_BY_PROFILE_AND_PROJECT_REQUEST'
export const INVESTMENTS_BY_PROFILE_AND_PROJECT_SUCCESS = 'INVESTMENTS_BY_PROFILE_AND_PROJECT_SUCCESS'
export const INVESTMENTS_BY_PROFILE_AND_PROJECT_FAIL = 'INVESTMENTS_BY_PROFILE_AND_PROJECT_FAIL'
export const INVESTMENTS_BY_PROFILE_AND_PROJECT_RESET = 'INVESTMENTS_BY_PROFILE_AND_PROJECT_RESET'

export const INVESTMENTS_BY_PROJECT_REQUEST = 'INVESTMENTS_BY_PROJECT_REQUEST'
export const INVESTMENTS_BY_PROJECT_SUCCESS = 'INVESTMENTS_BY_PROJECT_SUCCESS'
export const INVESTMENTS_BY_PROJECT_FAIL = 'INVESTMENTS_BY_PROJECT_FAIL'
export const INVESTMENTS_BY_PROJECT_RESET = 'INVESTMENTS_BY_PROJECT_RESET'

export const INVESTMENTS_BY_STATUS_AND_PROFILE_REQUEST = 'INVESTMENTS_BY_STATUS_AND_PROFILE_REQUEST'
export const INVESTMENTS_BY_STATUS_AND_PROFILE_SUCCESS = 'INVESTMENTS_BY_STATUS_AND_PROFILE_SUCCESS'
export const INVESTMENTS_BY_STATUS_AND_PROFILE_FAIL = 'INVESTMENTS_BY_STATUS_AND_PROFILE_FAIL'
export const INVESTMENTS_BY_STATUS_AND_PROFILE_RESET = 'INVESTMENTS_BY_STATUS_AND_PROFILE_RESET'

export const INVESTMENT_LIST_REQUEST = 'INVESTMENT_LIST_REQUEST'
export const INVESTMENT_LIST_SUCCESS = 'INVESTMENT_LIST_SUCCESS'
export const INVESTMENT_LIST_FAIL = 'INVESTMENT_LIST_FAIL'
export const INVESTMENT_LIST_RESET = 'INVESTMENT_LIST_RESET'

export const UNFINISHED_INVESTMENT_LIST_REQUEST = 'UNFINISHED_INVESTMENT_LIST_REQUEST'
export const UNFINISHED_INVESTMENT_LIST_SUCCESS = 'UNFINISHED_INVESTMENT_LIST_SUCCESS'
export const UNFINISHED_INVESTMENT_LIST_FAIL = 'UNFINISHED_INVESTMENT_LIST_FAIL'
export const UNFINISHED_INVESTMENT_LIST_RESET = 'UNFINISHED_INVESTMENT_LIST_RESET'

export const LIQUIDATED_INVESTMENT_LIST_REQUEST = 'LIQUIDATED_INVESTMENT_LIST_REQUEST'
export const LIQUIDATED_INVESTMENT_LIST_SUCCESS = 'LIQUIDATED_INVESTMENT_LIST_SUCCESS'
export const LIQUIDATED_INVESTMENT_LIST_FAIL = 'LIQUIDATED_INVESTMENT_LIST_FAIL'
export const LIQUIDATED_INVESTMENT_LIST_RESET = 'LIQUIDATED_INVESTMENT_LIST_RESET'

export const INVESTMENT_BY_ID_REQUEST = 'INVESTMENT_BY_ID_REQUEST'
export const INVESTMENT_BY_ID_SUCCESS = 'INVESTMENT_BY_ID_SUCCESS'
export const INVESTMENT_BY_ID_FAIL = 'INVESTMENT_BY_ID_FAIL'
export const INVESTMENT_BY_ID_RESET = 'INVESTMENT_BY_ID_RESET'

export const INVESTMENT_PROFILE_LIST_REQUEST = 'INVESTMENT_PROFILE_LIST_REQUEST'
export const INVESTMENT_PROFILE_LIST_SUCCESS = 'INVESTMENT_PROFILE_LIST_SUCCESS'
export const INVESTMENT_PROFILE_LIST_FAIL = 'INVESTMENT_PROFILE_LIST_FAIL'
export const INVESTMENT_PROFILE_LIST_RESET = 'INVESTMENT_PROFILE_LIST_RESET'

export const INVESTMENT_UPLOAD_CONTRACT_REQUEST = 'INVESTMENT_UPLOAD_CONTRACT_REQUEST'
export const INVESTMENT_UPLOAD_CONTRACT_SUCCESS = 'INVESTMENT_UPLOAD_CONTRACT_SUCCESS'
export const INVESTMENT_UPLOAD_CONTRACT_FAIL = 'INVESTMENT_UPLOAD_CONTRACT_FAIL'
export const INVESTMENT_UPLOAD_CONTRACT_RESET = 'INVESTMENT_UPLOAD_CONTRACT_RESET'

export const INVESTMENT_SIGN_CONTRACT_REQUEST = 'INVESTMENT_SIGN_CONTRACT_REQUEST'
export const INVESTMENT_SIGN_CONTRACT_SUCCESS = 'INVESTMENT_SIGN_CONTRACT_SUCCESS'
export const INVESTMENT_SIGN_CONTRACT_FAIL = 'INVESTMENT_SIGN_CONTRACT_FAIL'
export const INVESTMENT_SIGN_CONTRACT_RESET = 'INVESTMENT_SIGN_CONTRACT_RESET'

export const INVESTMENT_LAST_LIST_REQUEST = 'INVESTMENT_LAST_LIST_REQUEST'
export const INVESTMENT_LAST_LIST_SUCCESS = 'INVESTMENT_LAST_LIST_SUCCESS'
export const INVESTMENT_LAST_LIST_FAIL = 'INVESTMENT_LAST_LIST_FAIL'
export const INVESTMENT_LAST_LIST_RESET = 'INVESTMENT_LAST_LIST_RESET'

export const INVESTMENT_BY_PROFILE_LIST_REQUEST = 'INVESTMENT_BY_PROFILE_LIST_REQUEST'
export const INVESTMENT_BY_PROFILE_LIST_SUCCESS = 'INVESTMENT_BY_PROFILE_LIST_SUCCESS'
export const INVESTMENT_BY_PROFILE_LIST_FAIL = 'INVESTMENT_BY_PROFILE_LIST_FAIL'
export const INVESTMENT_BY_PROFILE_LIST_RESET = 'INVESTMENT_BY_PROFILE_LIST_RESET'

export const INVESTMENT_BY_USER_LIST_REQUEST = 'INVESTMENT_BY_USER_LIST_REQUEST'
export const INVESTMENT_BY_USER_LIST_SUCCESS = 'INVESTMENT_BY_USER_LIST_SUCCESS'
export const INVESTMENT_BY_USER_LIST_FAIL = 'INVESTMENT_BY_USER_LIST_FAIL'
export const INVESTMENT_BY_USER_LIST_RESET = 'INVESTMENT_BY_USER_LIST_RESET'

export const INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_REQUEST = 'INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_REQUEST'
export const INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_SUCCESS = 'INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_SUCCESS'
export const INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_FAIL = 'INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_FAIL'
export const INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_RESET = 'INVESTMENT_UPDATE_PRESCRIPTION_LIQUIDATED_RESET'

export const INVESTMENT_BY_DATE_AND_INVESTOR_REQUEST = 'INVESTMENT_BY_DATE_AND_INVESTOR_REQUEST'
export const INVESTMENT_BY_DATE_AND_INVESTOR_SUCCESS = 'INVESTMENT_BY_DATE_AND_INVESTOR_SUCCESS'
export const INVESTMENT_BY_DATE_AND_INVESTOR_FAIL = 'INVESTMENT_BY_DATE_AND_INVESTOR_FAIL'
export const INVESTMENT_BY_DATE_AND_INVESTOR_RESET = 'INVESTMENT_BY_DATE_AND_INVESTOR_RESET'

export const INVESTS_GET_NOTES_REQUEST = 'INVESTS_GET_NOTES_REQUEST'
export const INVESTS_GET_NOTES_REQUEST_ADD = 'INVESTS_GET_NOTES_REQUEST_ADD'
export const INVESTS_GET_NOTES_SUCCESS = 'INVESTS_GET_NOTES_SUCCESS'
export const INVESTS_GET_NOTES_FAIL = 'INVESTS_GET_NOTES_FAIL'
export const INVESTS_GET_NOTES_FAIL_RESET = 'INVESTS_GET_NOTES_FAIL_RESET'
export const INVESTS_GET_NOTES_SUCCESS_ADDED = 'INVESTS_GET_NOTES_SUCCESS_ADDED'
export const INVESTS_GET_NOTES_RESET = 'INVESTS_GET_NOTES_RESET'

export const TEN_DAYS_IN_MILLISECONDS = 864000000

export const GET_RETENTION_CERTIFICATE_BY_INVESTMENT_REQUEST = 'GET_RETENTION_CERTIFICATE_BY_INVESTMENT_REQUEST'
export const GET_RETENTION_CERTIFICATE_BY_INVESTMENT_SUCCESS = 'GET_RETENTION_CERTIFICATE_BY_INVESTMENT_SUCCESS'
export const GET_RETENTION_CERTIFICATE_BY_INVESTMENT_FAIL = 'GET_RETENTION_CERTIFICATE_BY_INVESTMENT_FAIL'
export const GET_RETENTION_CERTIFICATE_BY_INVESTMENT_RESET = 'GET_RETENTION_CERTIFICATE_BY_INVESTMENT_RESET'

export const GET_CONTRACTS_INFO_TO_DOWNLOAD_REQUEST = 'GET_CONTRACTS_INFO_TO_DOWNLOAD_REQUEST'
export const GET_CONTRACTS_INFO_TO_DOWNLOAD_SUCCESS = 'GET_CONTRACTS_INFO_TO_DOWNLOAD_SUCCESS'
export const GET_CONTRACTS_INFO_TO_DOWNLOAD_FAIL = 'GET_CONTRACTS_INFO_TO_DOWNLOAD_FAIL'
export const GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET = 'GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET'

export const SEND_PRESCRIPTOR_CONTRACT_REQUEST = 'SEND_PRESCRIPTOR_CONTRACT_REQUEST'
export const SEND_PRESCRIPTOR_CONTRACT_SUCCESS = 'SEND_PRESCRIPTOR_CONTRACT_SUCCESS'
export const SEND_PRESCRIPTOR_CONTRACT_FAIL = 'SEND_PRESCRIPTOR_CONTRACT_FAIL'
export const SEND_PRESCRIPTOR_CONTRACT_RESET = 'SEND_PRESCRIPTOR_CONTRACT_RESET'
