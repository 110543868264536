import axios from 'axios'
import {
	PRESCRIBER_LIST_REQUEST,
	PRESCRIBER_LIST_SUCCESS,
	PRESCRIBER_LIST_FAIL,
	PRESCRIBER_REFERER_LIST_BY_ID_REQUEST,
	PRESCRIBER_REFERER_LIST_BY_ID_SUCCESS,
	PRESCRIBER_REFERER_LIST_BY_ID_FAIL,
	PRESCRIBER_PROFILE_LIST_BY_ID_REQUEST,
	PRESCRIBER_PROFILE_LIST_BY_ID_SUCCESS,
	PRESCRIBER_PROFILE_LIST_BY_ID_FAIL,
} from '../constants/prescriberConstants'

export const getPrescriberList = () => async (dispatch, getState) => {
	try {
		dispatch({ type: PRESCRIBER_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/prescribers', config)

		dispatch({ type: PRESCRIBER_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: PRESCRIBER_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getPrescriberRefererListById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: PRESCRIBER_REFERER_LIST_BY_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/prescribers/${id}`, config)

		dispatch({ type: PRESCRIBER_REFERER_LIST_BY_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: PRESCRIBER_REFERER_LIST_BY_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getPrescriberProfileListById = () => async (dispatch, getState) => {
	try {
		dispatch({ type: PRESCRIBER_PROFILE_LIST_BY_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/prescribers/profile/${userInfo._id}`, config)

		dispatch({ type: PRESCRIBER_PROFILE_LIST_BY_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: PRESCRIBER_PROFILE_LIST_BY_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
