import axios from 'axios'
import {
	FIXED_RATE_LOANS_LIST_REQUEST,
	FIXED_RATE_LOANS_LIST_SUCCESS,
	FIXED_RATE_LOANS_LIST_FAIL,
	FIXED_RATE_LOANS_REGISTER_REQUEST,
	FIXED_RATE_LOANS_REGISTER_SUCCESS,
	FIXED_RATE_LOANS_REGISTER_FAIL,
	FIXED_RATE_LOANS_UPDATE_BY_ID_REQUEST,
	FIXED_RATE_LOANS_UPDATE_BY_ID_SUCCESS,
	FIXED_RATE_LOANS_UPDATE_BY_ID_FAIL,
	FIXED_RATE_LOANS_DELETE_BY_ID_REQUEST,
	FIXED_RATE_LOANS_DELETE_BY_ID_SUCCESS,
	FIXED_RATE_LOANS_DELETE_BY_ID_FAIL,
} from '../constants/fixedRateLoansConstants'

export const getAllFixedRateLoansById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: FIXED_RATE_LOANS_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(`/api/fixed-rate-loans/${id}`, config)

		dispatch({ type: FIXED_RATE_LOANS_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: FIXED_RATE_LOANS_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const getFixedRateLoans = () => async (dispatch, getState) => {
	try {
		dispatch({ type: FIXED_RATE_LOANS_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('api/fixed-rate-loans', config)

		dispatch({ type: FIXED_RATE_LOANS_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: FIXED_RATE_LOANS_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const registerFixedRateLoans = (fixedRateLoansData) => async (dispatch, getState) => {
	try {
		dispatch({ type: FIXED_RATE_LOANS_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('api/fixed-rate-loans', fixedRateLoansData, config)

		dispatch({ type: FIXED_RATE_LOANS_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: FIXED_RATE_LOANS_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const updateFixedRateLoansById = (fixedRateLoan) => async (dispatch, getState) => {
	try {
		dispatch({ type: FIXED_RATE_LOANS_UPDATE_BY_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/fixed-rate-loans/${fixedRateLoan._id}`, fixedRateLoan, config)

		dispatch({ type: FIXED_RATE_LOANS_UPDATE_BY_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: FIXED_RATE_LOANS_UPDATE_BY_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}

export const deleteFixedRateLoansById = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: FIXED_RATE_LOANS_DELETE_BY_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.delete(`/api/fixed-rate-loans/${id}`, config)

		dispatch({ type: FIXED_RATE_LOANS_DELETE_BY_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: FIXED_RATE_LOANS_DELETE_BY_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const updateIncomeReciveFixedRateLoansById = (id, dataToSend) => async (dispatch, getState) => {
	try {
		dispatch({ type: FIXED_RATE_LOANS_UPDATE_BY_ID_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(`/api/fixed-rate-loans/fields/${id}`, dataToSend, config)

		dispatch({ type: FIXED_RATE_LOANS_UPDATE_BY_ID_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: FIXED_RATE_LOANS_UPDATE_BY_ID_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
