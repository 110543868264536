import axios from 'axios'
import {
  END_OF_PROJECT_DOCUMENT_REGISTER_REQUEST,
  END_OF_PROJECT_DOCUMENT_REGISTER_SUCCESS,
  END_OF_PROJECT_DOCUMENT_REGISTER_FAIL,
  END_OF_PROJECT_DOCUMENT_DELETE_REQUEST,
  END_OF_PROJECT_DOCUMENT_DELETE_SUCCESS,
  END_OF_PROJECT_DOCUMENT_DELETE_FAIL,
} from '../constants/endOfProjectDocumentConstants'

export const registerEndOfProjectDocument = (documentInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: END_OF_PROJECT_DOCUMENT_REGISTER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const fieldsFiles = ['endOfProjectDossier']

    let formData = new FormData()

    for (let key in documentInfo) {
      if (!fieldsFiles.includes(key)) {
        formData.append(key, documentInfo[key])
      }
    }

    formData.append('endOfProjectDossier', documentInfo.endOfProjectDossier[0])

    const { data } = await axios.post('/api/documents', formData, config)

    dispatch({ type: END_OF_PROJECT_DOCUMENT_REGISTER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: END_OF_PROJECT_DOCUMENT_REGISTER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deleteEndOfProjectDocument = (documentId) => async (dispatch, getState) => {
  try {
    dispatch({ type: END_OF_PROJECT_DOCUMENT_DELETE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(`/api/documents/${documentId}`, config)

    dispatch({ type: END_OF_PROJECT_DOCUMENT_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: END_OF_PROJECT_DOCUMENT_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
