import * as types from '../constants/fixedRateLoansConstants'

export const fixedRateLoansListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.FIXED_RATE_LOANS_LIST_REQUEST:
			return {
				loadingListFixedRateLoans: true,
			}
		case types.FIXED_RATE_LOANS_LIST_SUCCESS:
			return {
				successFixedRateLoansList: true,
				loadingListFixedRateLoans: false,
				FixedRateLoansListData: action.payload,
			}
		case types.FIXED_RATE_LOANS_LIST_FAIL:
			return {
				loadingListFixedRateLoans: false,
				errorFixedRateLoansList: action.payload,
			}
		case types.FIXED_RATE_LOANS_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const fixedRateLoansRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.FIXED_RATE_LOANS_REGISTER_REQUEST:
			return {
				loadingRegisterFixedRateLoans: true,
			}
		case types.FIXED_RATE_LOANS_REGISTER_SUCCESS:
			return {
				successRegisterFixedRateLoans: true,
				loadingRegisterFixedRateLoans: false,
				registeredFixedRateLoansData: action.payload,
			}
		case types.FIXED_RATE_LOANS_REGISTER_FAIL:
			return {
				loadingRegisterFixedRateLoans: false,
				errorRegisterFixedRateLoans: action.payload,
			}
		case types.FIXED_RATE_LOANS_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const fixedRateLoansUpdateByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case types.FIXED_RATE_LOANS_UPDATE_BY_ID_REQUEST:
			return {
				loadingUpdateFixedRateLoansById: true,
			}
		case types.FIXED_RATE_LOANS_UPDATE_BY_ID_SUCCESS:
			return {
				successUpdateFixedRateLoansById: true,
				loadingUpdateFixedRateLoansById: false,
				upDateFixedRateLoansData: action.payload,
			}
		case types.FIXED_RATE_LOANS_UPDATE_BY_ID_FAIL:
			return {
				successUpdateFixedRateLoansById: false,
				loadingUpdateFixedRateLoansById: false,
				errorUpdateFixedRateLoansById: action.payload,
			}
		case types.FIXED_RATE_LOANS_UPDATE_BY_ID_RESET:
			return {}
		default:
			return state
	}
}

export const fixedRateLoansDeleteByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case types.FIXED_RATE_LOANS_DELETE_BY_ID_REQUEST:
			return {
				loadingDeleteFixedRateLoansById: true,
			}
		case types.FIXED_RATE_LOANS_DELETE_BY_ID_SUCCESS:
			return {
				successDeleteFixedRateLoansById: true,
				loadingDeleteFixedRateLoansById: false,
				deleteFixedRateLoansById: action.payload,
			}
		case types.FIXED_RATE_LOANS_DELETE_BY_ID_FAIL:
			return {
				loadingDeleteFixedRateLoansById: false,
				errorDeleteFixedRateLoansById: action.payload,
			}
		case types.FIXED_RATE_LOANS_DELETE_BY_ID_RESET:
			return {}
		default:
			return state
	}
}
