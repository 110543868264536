import {
  OWNER_PROFILE_WITH_USER_REGISTER_REQUEST,
  OWNER_PROFILE_WITH_USER_REGISTER_SUCCESS,
  OWNER_PROFILE_WITH_USER_REGISTER_FAIL,
  OWNER_PROFILE_WITH_USER_REGISTER_RESET,
  OWNER_PROFILE_BY_ID_REQUEST,
  OWNER_PROFILE_BY_ID_SUCCESS,
  OWNER_PROFILE_BY_ID_FAIL,
  OWNER_PROFILE_BY_ID_RESET,
  OWNER_PROFILE_BY_USER_REQUEST,
  OWNER_PROFILE_BY_USER_SUCCESS,
  OWNER_PROFILE_BY_USER_FAIL,
  OWNER_PROFILE_BY_USER_RESET,
  OWNER_PROFILE_DELETE_REQUEST,
  OWNER_PROFILE_DELETE_SUCCESS,
  OWNER_PROFILE_DELETE_FAIL,
  OWNER_PROFILE_DELETE_RESET,
} from 'local_redux/constants/ownersProfileConstants'

export const ownerProfileWithUserRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case OWNER_PROFILE_WITH_USER_REGISTER_REQUEST:
      return { loadingOwnerProfileWithUserRegister: true }
    case OWNER_PROFILE_WITH_USER_REGISTER_SUCCESS:
      return {
        successOwnerProfileWithUserRegister: true,
        loadingOwnerProfileWithUserRegister: false,
        OwnerProfileWithUserRegisterData: action.payload,
      }
    case OWNER_PROFILE_WITH_USER_REGISTER_FAIL:
      return {
        loadingOwnerProfileWithUserRegister: false,
        errorOwnerProfileWithUserRegister: action.payload,
      }
    case OWNER_PROFILE_WITH_USER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const ownerProfileByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case OWNER_PROFILE_BY_ID_REQUEST:
      return { loadingOwnerProfileById: true }
    case OWNER_PROFILE_BY_ID_SUCCESS:
      return {
        successOwnerProfileById: true,
        loadingOwnerProfileById: false,
        ownerProfileByIdData: action.payload,
      }
    case OWNER_PROFILE_BY_ID_FAIL:
      return {
        loadingOwnerProfileById: false,
        errorOwnerProfileById: action.payload,
      }
    case OWNER_PROFILE_BY_ID_RESET:
      return {}
    default:
      return state
  }
}

export const ownerProfileByUserReducer = (state = {}, action) => {
  switch (action.type) {
    case OWNER_PROFILE_BY_USER_REQUEST:
      return { loadingOwnerProfileByUser: true }
    case OWNER_PROFILE_BY_USER_SUCCESS:
      return {
        successOwnerProfileByUser: true,
        loadingOwnerProfileByUser: false,
        OwnerProfileByUserData: action.payload,
      }
    case OWNER_PROFILE_BY_USER_FAIL:
      return {
        loadingOwnerProfileByUser: false,
        errorOwnerProfileByUser: action.payload,
      }
    case OWNER_PROFILE_BY_USER_RESET:
      return {}
    default:
      return state
  }
}

export const ownerProfileDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case OWNER_PROFILE_DELETE_REQUEST:
      return { loadingOwnerProfileDelete: true }
    case OWNER_PROFILE_DELETE_SUCCESS:
      return {
        successOwnerProfileDelete: true,
        loadingOwnerProfileDelete: false,
        ownerProfileDeleteData: action.payload,
      }
    case OWNER_PROFILE_DELETE_FAIL:
      return {
        loadingOwnerProfileDelete: false,
        errorOwnerProfileDelete: action.payload,
      }
    case OWNER_PROFILE_DELETE_RESET:
      return {}
    default:
      return state
  }
}
