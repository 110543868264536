import {
	drawerWidth,
	drawerMiniWidth,
	transition,
	containerFluid,
	loaderContainer,
	grayColor,
} from 'assets/jss/material-dashboard-pro-react'

const appStyle = (theme) => ({
	loaderContainer,
	wrapper: {
		position: 'relative',
		top: '0',
		height: '100vh',
		'&:after': {
			display: 'table',
			clear: 'both',
			content: '" "',
		},
	},
	mainPanel: {
		backgroundColor: '#f2f1eb',
		color: '#000',
		transitionProperty: 'top, bottom, width',
		transitionDuration: '.2s, .2s, .35s',
		transitionTimingFunction: 'linear, linear, ease',
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerWidth}px)`,
		},
		overflow: 'auto',
		position: 'relative',
		float: 'right',
		...transition,
		maxHeight: '100%',
		width: '100%',
		overflowScrolling: 'touch',

		'&::-webkit-scrollbar': {
			width: '8px',
		},
		'&::-webkit-scrollbar-track': {
			background: grayColor[5],
			borderRadius: '8px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#9999998a',
			borderRadius: '8px',
		},
	},
	content: {
		marginTop: '70px',
		padding: '30px 15px',
		minHeight: 'calc(100vh - 123px)',
	},
	container: { ...containerFluid },
	map: {
		marginTop: '70px',
	},
	mainPanelSidebarMini: {
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerMiniWidth}px)`,
		},
	},
	mainPanelWithPerfectScrollbar: {
		overflowX: 'hidden',
		// overflow: 'hidden !important',
	},
	'@media (max-width:600px)': {
		content: {
			padding: '30px 0px',
		},
	},
})

export default appStyle
