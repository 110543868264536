import { useState, createRef, useEffect, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Outlet } from 'react-router-dom'
import cx from 'classnames'
// import PerfectScrollbar from 'perfect-scrollbar'
// import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { makeStyles } from '@material-ui/core'
import AdminNavbar from 'components/Navbars/AdminNavbar'
import Sidebar from 'components/Sidebar/Sidebar'
import Loader from 'components/Loader/Loader'
import routes from 'routes/routes'
import { SocketContextProvider } from 'contexts/socketContext'
import { refreshToken } from 'local_redux/actions/userActions'
import styles from './styles/adminStyle'

// let ps

const useStyles = makeStyles(styles)

const AdminLayout = ({ ...rest }) => {
	const classes = useStyles()
	const navigate = useNavigate()
	const mainPanel = createRef()
	const dispatch = useDispatch()

	const [mobileOpen, setMobileOpen] = useState(false)
	const [miniActive, setMiniActive] = useState(false)

	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		dispatch(refreshToken())
		if (!userInfo) {
			navigate('/auth/login')
		}
	}, [window.location.pathname])

	const mainPanelClasses = cx({
		[classes.mainPanel]: true,
		[classes.mainPanelSidebarMini]: miniActive,
		[classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
	})

	// useEffect(() => {
	// 	if (navigator.platform.indexOf('Win') > -1) {
	// 		ps = new PerfectScrollbar(mainPanel.current, {
	// 			suppressScrollX: true,
	// 			suppressScrollY: false,
	// 		})
	// 		document.body.style.overflow = 'hidden'
	// 	}
	// 	window.addEventListener('resize', resizeFunction)

	// 	// Specify how to clean up after this effect:
	// 	return function cleanup() {
	// 		if (navigator.platform.indexOf('Win') > -1) {
	// 			ps.destroy()
	// 		}
	// 		window.removeEventListener('resize', resizeFunction)
	// 	}
	// })

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text'
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views)
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name
				}
			}
		}
		return activeRoute
	}
	const sidebarMinimize = () => {
		setMiniActive(!miniActive)
	}
	const getRoutesByRole = (routes) => {
		if (userInfo?.isDefaultPassword) {
			return routes.filter((route) => {
				return route.path === '/user-page'
			})
		}

		return routes
	}

	return (
		<SocketContextProvider>
			<div className={classes.wrapper}>
				<Sidebar
					routes={getRoutesByRole(routes)}
					logoText={'Chapnik & Giesen'}
					handleDrawerToggle={handleDrawerToggle}
					open={mobileOpen}
					miniActive={miniActive}
					{...rest}
				/>
				<div id='mainPanel' className={mainPanelClasses} ref={mainPanel}>
					<AdminNavbar
						sidebarMinimize={sidebarMinimize.bind(this)}
						miniActive={miniActive}
						brandText={getActiveRoute(routes)}
						handleDrawerToggle={handleDrawerToggle}
						{...rest}
					/>
					<div className={classes.content}>
						<div className={classes.container}>
							<Suspense
								fallback={
									<div className={classes.loaderContainer}>
										<Loader />
									</div>
								}
							>
								<Outlet />
							</Suspense>
						</div>
					</div>
				</div>
			</div>
		</SocketContextProvider>
	)
}

export default AdminLayout
