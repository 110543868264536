import axios from 'axios'
import { OWNER_LIST_REQUEST, OWNER_LIST_SUCCESS, OWNER_LIST_FAIL } from 'local_redux/constants/ownersProfileConstants'

export const getAllUsersOwners = () => async (dispatch, getState) => {
  try {
    dispatch({ type: OWNER_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/owners', config)

    dispatch({ type: OWNER_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: OWNER_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
