import axios from 'axios'
import {
	FINISHED_PROJECT_REGISTER_REQUEST,
	FINISHED_PROJECT_REGISTER_SUCCESS,
	FINISHED_PROJECT_REGISTER_FAIL,
	FINISHED_PROJECT_LIST_REQUEST,
	FINISHED_PROJECT_LIST_SUCCESS,
	FINISHED_PROJECT_LIST_FAIL,
} from '../constants/finishedProjectConstants'

export const registerFinishedProject = (projectFinishedData) => async (dispatch, getState) => {
	try {
		dispatch({ type: FINISHED_PROJECT_REGISTER_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post('/api/finished-projects', projectFinishedData, config)

		dispatch({ type: FINISHED_PROJECT_REGISTER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: FINISHED_PROJECT_REGISTER_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
export const getAllFinishedProjects = () => async (dispatch, getState) => {
	try {
		dispatch({ type: FINISHED_PROJECT_LIST_REQUEST })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get('/api/finished-projects', config)

		dispatch({ type: FINISHED_PROJECT_LIST_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: FINISHED_PROJECT_LIST_FAIL,
			payload: error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
