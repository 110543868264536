import {
	ERASER_HEAD_PROJECT_REGISTER_REQUEST,
	ERASER_HEAD_PROJECT_REGISTER_SUCCESS,
	ERASER_HEAD_PROJECT_REGISTER_RESET,
	ERASER_HEAD_PROJECT_REGISTER_FAIL,
	ERASER_HEAD_PROJECT_UPDATE_RESET,
	ERASER_HEAD_PROJECT_UPDATE_FAIL,
	ERASER_HEAD_PROJECT_UPDATE_SUCCESS,
	ERASER_HEAD_PROJECT_UPDATE_REQUEST,
} from '../constants/eraserHeadProject'

export const eraserHeadProjectRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case ERASER_HEAD_PROJECT_REGISTER_REQUEST:
			return { loadingEraserHeadProjectRegister: true }
		case ERASER_HEAD_PROJECT_REGISTER_SUCCESS:
			return {
				successEraserHeadProjectRegister: true,
				loadingEraserHeadProjectRegister: false,
				eraserHeadProjectRegisterData: action.payload,
			}
		case ERASER_HEAD_PROJECT_REGISTER_FAIL:
			return {
				loadingEraserHeadProjectRegister: false,
				errorEraserHeadProjectRegister: action.payload,
			}
		case ERASER_HEAD_PROJECT_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const eraserHeadProjectUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case ERASER_HEAD_PROJECT_UPDATE_REQUEST:
			return { loadingEraserHeadProjectUpdate: true }
		case ERASER_HEAD_PROJECT_UPDATE_SUCCESS:
			return {
				successEraserHeadProjectUpdate: true,
				loadingEraserHeadProjectUpdate: false,
				eraserHeadProjectUpdateData: action.payload,
			}
		case ERASER_HEAD_PROJECT_UPDATE_FAIL:
			return {
				loadingEraserHeadProjectUpdate: false,
				errorEraserHeadProjectUpdate: action.payload,
			}
		case ERASER_HEAD_PROJECT_UPDATE_RESET:
			return {}
		default:
			return state
	}
}
