export const RETENTION_REGISTER_REQUEST = 'RETENTION_REGISTER_REQUEST'
export const RETENTION_REGISTER_SUCCESS = 'RETENTION_REGISTER_SUCCESS'
export const RETENTION_REGISTER_FAIL = 'RETENTION_REGISTER_FAIL'
export const RETENTION_REGISTER_RESET = 'RETENTION_REGISTER_RESET'

export const RETENTION_LIST_REQUEST = 'RETENTION_LIST_REQUEST'
export const RETENTION_LIST_SUCCESS = 'RETENTION_LIST_SUCCESS'
export const RETENTION_LIST_FAIL = 'RETENTION_LIST_FAIL'
export const RETENTION_LIST_RESET = 'RETENTION_LIST_RESET'

export const RETENTION_UPDATE_REQUEST = 'RETENTION_UPDATE_REQUEST'
export const RETENTION_UPDATE_SUCCESS = 'RETENTION_UPDATE_SUCCESS'
export const RETENTION_UPDATE_FAIL = 'RETENTION_UPDATE_FAIL'
export const RETENTION_UPDATE_RESET = 'RETENTION_UPDATE_RESET'

export const RETENTION_DELETE_REQUEST = 'RETENTION_DELETE_REQUEST'
export const RETENTION_DELETE_SUCCESS = 'RETENTION_DELETE_SUCCESS'
export const RETENTION_DELETE_FAIL = 'RETENTION_DELETE_FAIL'
export const RETENTION_DELETE_RESET = 'RETENTION_DELETE_RESET'
