import { createRef, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import AuthNavbar from 'components/Navbars/AuthNavbar'
import BackgroundImage from 'components/BackgroundImage/BackgroundImage'
import Footer from 'components/Footer/Footer'
import authRoutes from 'routes/authRoutes'
import login from 'assets/img/login.png'
import loginPreload from 'assets/img/login-preload.png'
import { brandName } from 'shared/variables/general'
import styles from './styles/authStyle'

const useStyles = makeStyles(styles)

const AuthLayout = ({ ...rest }) => {
  const wrapper = createRef()
  const classes = useStyles()

  useEffect(() => {
    document.body.style.overflow = 'unset'

    return function cleanup() {}
  })

  const getActiveRoute = (authRoutes) => {
    let activeRoute = brandName
    for (let i = 0; i < authRoutes.length; i++) {
      if (window.location.href.indexOf(authRoutes[i].layout + authRoutes[i].path) !== -1) {
        return authRoutes[i].name
      }
    }
    return activeRoute
  }

  return (
    <div>
      <AuthNavbar brandText={getActiveRoute(authRoutes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <BackgroundImage preImage={loginPreload} loadedImage={login} />
        <div className={classes.fullPage}>
          <Outlet />
          <Footer white />
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
