export const PRESCRIBER_LIST_REQUEST = 'PRESCRIBER_LIST_REQUEST'
export const PRESCRIBER_LIST_SUCCESS = 'PRESCRIBER_LIST_SUCCESS'
export const PRESCRIBER_LIST_FAIL = 'PRESCRIBER_LIST_FAIL'
export const PRESCRIBER_LIST_RESET = 'PRESCRIBER_LIST_RESET'

export const PRESCRIBER_REFERER_LIST_BY_ID_REQUEST = 'PRESCRIBER_REFERER_LIST_BY_ID_REQUEST'
export const PRESCRIBER_REFERER_LIST_BY_ID_SUCCESS = 'PRESCRIBER_REFERER_LIST_BY_ID_SUCCESS'
export const PRESCRIBER_REFERER_LIST_BY_ID_FAIL = 'PRESCRIBER_REFERER_LIST_BY_ID_FAIL'
export const PRESCRIBER_REFERER_LIST_BY_ID_RESET = 'PRESCRIBER_REFERER_LIST_BY_ID_RESET'

export const PRESCRIBER_PROFILE_LIST_BY_ID_REQUEST = 'PRESCRIBER_PROFILE_LIST_BY_ID_REQUEST'
export const PRESCRIBER_PROFILE_LIST_BY_ID_SUCCESS = 'PRESCRIBER_PROFILE_LIST_BY_ID_SUCCESS'
export const PRESCRIBER_PROFILE_LIST_BY_ID_FAIL = 'PRESCRIBER_PROFILE_LIST_BY_ID_FAIL'
export const PRESCRIBER_PROFILE_LIST_BY_ID_RESET = 'PRESCRIBER_PROFILE_LIST_BY_ID_RESET'
