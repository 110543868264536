import {
  BACKUP_LIST_REQUEST,
  BACKUP_LIST_SUCCESS,
  BACKUP_LIST_FAIL,
  BACKUP_LIST_RESET,
  BACKUP_DOWNLOAD_DIR_REQUEST,
  BACKUP_DOWNLOAD_DIR_SUCCESS,
  BACKUP_DOWNLOAD_DIR_FAIL,
  BACKUP_DOWNLOAD_DIR_RESET,
} from '../constants/backupConstants'

export const backupListReducer = (state = {}, action) => {
  switch (action.type) {
    case BACKUP_LIST_REQUEST:
      return { loadingBackupList: true }
    case BACKUP_LIST_SUCCESS:
      return {
        successBackupList: true,
        loadingBackupList: false,
        backupListData: action.payload,
      }
    case BACKUP_LIST_FAIL:
      return {
        loadingBackupList: false,
        errorBackupList: action.payload,
      }
    case BACKUP_LIST_RESET:
      return {}
    default:
      return state
  }
}
export const backupDownloadDirReducer = (state = {}, action) => {
  switch (action.type) {
    case BACKUP_DOWNLOAD_DIR_REQUEST:
      return { loadingDownloadDir: true }
    case BACKUP_DOWNLOAD_DIR_SUCCESS:
      return {
        successDownloadDir: true,
        loadingDownloadDir: false,
        downloadDirData: action.payload,
      }
    case BACKUP_DOWNLOAD_DIR_FAIL:
      return {
        loadingDownloadDir: false,
        errorDownloadDir: action.payload,
      }
    case BACKUP_DOWNLOAD_DIR_RESET:
      return {}
    default:
      return state
  }
}
