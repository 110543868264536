import {
  PRE_REGISTER_REGISTER_FAIL,
  PRE_REGISTER_REGISTER_REQUEST,
  PRE_REGISTER_REGISTER_RESET,
  PRE_REGISTER_REGISTER_SUCCESS,
  PRE_REGISTER_USER_REQUEST,
  PRE_REGISTER_USER_SUCCESS,
  PRE_REGISTER_USER_FAIL,
  PRE_REGISTER_USER_RESET,
  PRE_REGISTER_BY_EMAIL_REQUEST,
  PRE_REGISTER_BY_EMAIL_SUCCESS,
  PRE_REGISTER_BY_EMAIL_RESET,
  PRE_REGISTER_BY_EMAIL_FAIL,
  PRE_REGISTER_LIST_REQUEST,
  PRE_REGISTER_LIST_SUCCESS,
  PRE_REGISTER_LIST_FAIL,
  PRE_REGISTER_LIST_RESET,
  PRE_REGISTER_UPDATE_REQUEST,
  PRE_REGISTER_UPDATE_SUCCESS,
  PRE_REGISTER_UPDATE_FAIL,
  PRE_REGISTER_UPDATE_RESET,
  PRE_REGISTER_DELETE_REQUEST,
  PRE_REGISTER_DELETE_SUCCESS,
  PRE_REGISTER_DELETE_FAIL,
  PRE_REGISTER_DELETE_RESET,
} from '../constants/preRegisterConstants'

export const preRegisterRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case PRE_REGISTER_REGISTER_REQUEST:
      return { loadingPreRegisterRegister: true }
    case PRE_REGISTER_REGISTER_SUCCESS:
      return { loadingPreRegisterRegister: false, successPreRegisterRegister: true, preRegisterData: action.payload }
    case PRE_REGISTER_REGISTER_FAIL:
      return { loadingPreRegisterRegister: false, errorPreRegisterRegister: action.payload }
    case PRE_REGISTER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const preRegisterUserReducer = (state = {}, action) => {
  switch (action.type) {
    case PRE_REGISTER_USER_REQUEST:
      return { loadingPreRegisterUser: true }
    case PRE_REGISTER_USER_SUCCESS:
      return { loadingPreRegisterUser: false, successPreRegisterUser: true, preRegisterUserData: action.payload }
    case PRE_REGISTER_USER_FAIL:
      return { loadingPreRegisterUser: false, errorPreRegisterUser: action.payload }
    case PRE_REGISTER_USER_RESET:
      return {}
    default:
      return state
  }
}

export const preRegisterByEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case PRE_REGISTER_BY_EMAIL_REQUEST:
      return { loadingPreRegisterByEmail: true }
    case PRE_REGISTER_BY_EMAIL_SUCCESS:
      return {
        loadingPreRegisterByEmail: false,
        successPreRegisterByEmail: true,
        preRegisterByEmailData: action.payload,
      }
    case PRE_REGISTER_BY_EMAIL_FAIL:
      return { loadingPreRegisterByEmail: false, errorPreRegisterByEmail: action.payload }
    case PRE_REGISTER_BY_EMAIL_RESET:
      return {}
    default:
      return state
  }
}
export const preRegisterListReducer = (state = {}, action) => {
  switch (action.type) {
    case PRE_REGISTER_LIST_REQUEST:
      return { loadingPreRegisterList: true }
    case PRE_REGISTER_LIST_SUCCESS:
      return {
        loadingPreRegisterList: false,
        successPreRegisterList: true,
        preRegisterListData: action.payload,
      }
    case PRE_REGISTER_LIST_FAIL:
      return { loadingPreRegisterList: false, errorPreRegisterList: action.payload }
    case PRE_REGISTER_LIST_RESET:
      return {}
    default:
      return state
  }
}
export const preRegisterUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRE_REGISTER_UPDATE_REQUEST:
      return { loadingPreRegisterUpdate: true }
    case PRE_REGISTER_UPDATE_SUCCESS:
      return {
        loadingPreRegisterUpdate: false,
        successPreRegisterUpdate: true,
        preRegisterUpdateData: action.payload,
      }
    case PRE_REGISTER_UPDATE_FAIL:
      return { loadingPreRegisterUpdate: false, errorPreRegisterUpdate: action.payload }
    case PRE_REGISTER_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const preRegisterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRE_REGISTER_DELETE_REQUEST:
      return { loadingPreRegisterDelete: true }
    case PRE_REGISTER_DELETE_SUCCESS:
      return {
        loadingPreRegisterDelete: false,
        successPreRegisterDelete: true,
        preRegisterDeleteData: action.payload,
      }
    case PRE_REGISTER_DELETE_FAIL:
      return { loadingPreRegisterDelete: false, errorPreRegisterDelete: action.payload }
    case PRE_REGISTER_DELETE_RESET:
      return {}
    default:
      return state
  }
}
