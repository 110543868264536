import {
  KPIS_LIST_DATA_REQUEST,
  KPIS_LIST_DATA_SUCCESS,
  KPIS_LIST_DATA_RESET,
  KPIS_LIST_DATA_FAIL,
} from '../constants/kpisConstants'

export const kpisListReducer = (state = {}, action) => {
  switch (action.type) {
    case KPIS_LIST_DATA_REQUEST:
      return { loadingKpisList: true }
    case KPIS_LIST_DATA_SUCCESS:
      return {
        succesKpisList: true,
        loadingKpisList: false,
        kpisListData: action.payload,
      }
    case KPIS_LIST_DATA_FAIL:
      return {
        loadingKpisList: false,
        errorKpisList: action.payload,
      }
    case KPIS_LIST_DATA_RESET:
      return {}
    default:
      return state
  }
}
