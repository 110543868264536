import {
	NOTIFICATION_LIST_FAIL,
	NOTIFICATION_LIST_REQUEST,
	NOTIFICATION_LIST_RESET,
	NOTIFICATION_LIST_SUCCESS,
	NOTIFICATION_BY_ID_FAIL,
	NOTIFICATION_BY_ID_REQUEST,
	NOTIFICATION_BY_ID_RESET,
	NOTIFICATION_BY_ID_SUCCESS,
	NOTIFICATION_BY_USER_REQUEST,
	NOTIFICATION_BY_USER_SUCCESS,
	NOTIFICATION_BY_USER_FAIL,
	NOTIFICATION_BY_USER_RESET,
	NOTIFICATION_REGISTER_REQUEST,
	NOTIFICATION_REGISTER_SUCCESS,
	NOTIFICATION_REGISTER_FAIL,
	NOTIFICATION_REGISTER_RESET,
	NOTIFICATION_BY_TYPE_LIST_REQUEST,
	NOTIFICATION_BY_TYPE_LIST_SUCCESS,
	NOTIFICATION_BY_TYPE_LIST_FAIL,
	NOTIFICATION_BY_TYPE_LIST_RESET,
} from '../constants/notificationConstants'

export const notificationListReducer = (state = {}, action) => {
	switch (action.type) {
		case NOTIFICATION_LIST_REQUEST:
			return { loadingNotificationList: true }
		case NOTIFICATION_LIST_SUCCESS:
			return { loadingNotificationList: false, successNotificationList: true, notificationListData: action.payload }
		case NOTIFICATION_LIST_FAIL:
			return { loadingNotificationList: false, errorNotificationList: action.payload }
		case NOTIFICATION_LIST_RESET:
			return {}
		default:
			return state
	}
}
export const notificationByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case NOTIFICATION_BY_ID_REQUEST:
			return { loadingNotificationById: true }
		case NOTIFICATION_BY_ID_SUCCESS:
			return { loadingNotificationById: false, successNotificationById: true, notificationByIdData: action.payload }
		case NOTIFICATION_BY_ID_FAIL:
			return { loadingNotificationById: false, errorNotificationById: action.payload }
		case NOTIFICATION_BY_ID_RESET:
			return {}
		default:
			return state
	}
}
export const notificationByUserReducer = (state = {}, action) => {
	switch (action.type) {
		case NOTIFICATION_BY_USER_REQUEST:
			return { loadingNotificationByUser: true }
		case NOTIFICATION_BY_USER_SUCCESS:
			return {
				loadingNotificationByUser: false,
				successNotificationByUser: true,
				notificationByUserData: action.payload,
			}
		case NOTIFICATION_BY_USER_FAIL:
			return { loadingNotificationByUser: false, errorNotificationByUser: action.payload }
		case NOTIFICATION_BY_USER_RESET:
			return {}
		default:
			return state
	}
}

export const notificationRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case NOTIFICATION_REGISTER_REQUEST:
			return { loadingNotificationRegister: true }
		case NOTIFICATION_REGISTER_SUCCESS:
			return {
				loadingNotificationRegister: false,
				successNotificationRegister: true,
				notificationRegisterData: action.payload,
			}
		case NOTIFICATION_REGISTER_FAIL:
			return { loadingNotificationRegister: false, errorNotificationRegister: action.payload }
		case NOTIFICATION_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const notificationsDelayedReducer = (state = {}, action) => {
	switch (action.type) {
		case NOTIFICATION_BY_TYPE_LIST_REQUEST:
			return { loadingNotificationsDelayed: true }
		case NOTIFICATION_BY_TYPE_LIST_SUCCESS:
			return {
				loadingNotificationsDelayed: false,
				successNotificationsDelayed: true,
				notificationsDelayedList: action.payload,
			}
		case NOTIFICATION_BY_TYPE_LIST_FAIL:
			return { loadingNotificationsDelayed: false, errorNotificationsDelayed: action.payload }
		case NOTIFICATION_BY_TYPE_LIST_RESET:
			return {}
		default:
			return state
	}
}
