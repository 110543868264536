import * as types from '../constants/logConstants'

export const logRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LOG_REGISTER_REQUEST:
			return { loadingLogRegister: true }
		case types.LOG_REGISTER_SUCCESS:
			return { loadingLogRegister: false, successLogRegister: true }
		case types.LOG_REGISTER_FAIL:
			return { loadingLogRegister: false, errorLogRegister: action.payload }
		case types.LOG_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const logListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LOG_LIST_REQUEST:
			return { loadingLogList: true }
		case types.LOG_LIST_SUCCESS:
			return { loadingLogList: false, successLogList: true, logListData: action.payload }
		case types.LOG_LIST_FAIL:
			return { loadingLogList: false, errorLogList: action.payload }
		case types.LOG_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const logUsersLastLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LOG_USERS_LAST_LOGIN_REQUEST:
			return { ...state, loadingLogUsersLastLogin: true }
		case types.LOG_USERS_LAST_LOGIN_SUCCESS:
			return {
				loadingLogUsersLastLogin: false,
				successLogUsersLastLogin: true,
				logUsersLastLoginData: action.payload,
			}
		case types.LOG_USERS_LAST_LOGIN_FAIL:
			return { loadingLogUsersLastLogin: false, errorLogUsersLastLogin: action.payload }
		case types.LOG_USERS_LAST_LOGIN_RESET:
			return {}
		default:
			return state
	}
}

export const logDetailsReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LOG_DETAILS_REQUEST:
			return { loadingLogDetails: true }
		case types.LOG_DETAILS_SUCCESS:
			return { loadingLogDetails: false, log: action.payload }
		case types.LOG_DETAILS_FAIL:
			return { loadingLogDetails: false, errorLogDetails: action.payload }
		case types.LOG_DETAILS_RESET:
			return {}
		default:
			return state
	}
}

export const logDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LOG_DELETE_REQUEST:
			return { loadingLogDelete: true }
		case types.LOG_DELETE_SUCCESS:
			return { loadingLogDelete: false, successLogDelete: true }
		case types.LOG_DELETE_FAIL:
			return { loadingLogDelete: false, errorLogDelete: action.payload }
		case types.LOG_DELETE_RESET:
			return {}
		default:
			return state
	}
}

export const logDeleteManyReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LOG_DELETE_MANY_REQUEST:
			return { loadingLogDeleteMany: true }
		case types.LOG_DELETE_MANY_SUCCESS:
			return { loadingLogDeleteMany: false, successLogDeleteMany: true }
		case types.LOG_DELETE_MANY_FAIL:
			return { loadingLogDeleteMany: false, errorLogDeleteMany: action.payload }
		case types.LOG_DELETE_MANY_RESET:
			return {}
		default:
			return state
	}
}

export const logByTypeReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LOG_BY_TYPE_REQUEST:
			return { loadingLogByType: true }
		case types.LOG_BY_TYPE_SUCCESS:
			return { loadingLogByType: false, successLogByType: true, logByTypeData: action.payload }
		case types.LOG_BY_TYPE_FAIL:
			return { loadingLogByType: false, errorLogByType: action.payload }
		case types.LOG_BY_TYPE_RESET:
			return {}
		default:
			return state
	}
}

export const logSearchReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LOG_SEARCH_REQUEST:
			return { loadingLogSearch: true }
		case types.LOG_SEARCH_SUCCESS:
			return { loadingLogSearch: false, successLogSearch: true, logSearchData: action.payload }
		case types.LOG_SEARCH_FAIL:
			return { loadingLogSearch: false, errorLogSearch: action.payload }
		case types.LOG_SEARCH_RESET:
			return {}
		default:
			return state
	}
}

export const logProjectSeeInfoReducer = (state = {}, action) => {
	switch (action.type) {
		case types.LOG_PROJECT_SEE_INFO_REQUEST:
			return { ...state, loadingLog: true }
		case types.LOG_PROJECT_SEE_INFO_SUCCESS:
			return {
				loadingLog: false,
				successLogRequest: true,
				logSeeInfoData: action.payload,
			}
		case types.LOG_PROJECT_SEE_INFO_FAIL:
			return { loadingLog: false, errorLogSeeInfo: action.payload }
		case types.LOG_PROJECT_SEE_INFO_RESET:
			return {}
		default:
			return state
	}
}
