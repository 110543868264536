import { loaderContainer } from 'assets/jss/material-dashboard-pro-react'

const publicStyle = (theme) => ({
  loaderContainer,
  fullPage: {
    padding: '30px 0',
    position: 'relative',
    minHeight: '100vh',

    margin: '0',
    border: '0',
    alignItems: 'center',
    height: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minHeight: '700px!important',
    },
    '& footer': {
      position: 'absolute',
      bottom: '0',

      border: 'none !important',
    },
  },
})

export default publicStyle
