import {
  RETENTION_REGISTER_REQUEST,
  RETENTION_REGISTER_SUCCESS,
  RETENTION_REGISTER_FAIL,
  RETENTION_REGISTER_RESET,
  RETENTION_LIST_REQUEST,
  RETENTION_LIST_SUCCESS,
  RETENTION_LIST_FAIL,
  RETENTION_LIST_RESET,
  RETENTION_UPDATE_REQUEST,
  RETENTION_UPDATE_SUCCESS,
  RETENTION_UPDATE_FAIL,
  RETENTION_UPDATE_RESET,
  RETENTION_DELETE_REQUEST,
  RETENTION_DELETE_SUCCESS,
  RETENTION_DELETE_RESET,
  RETENTION_DELETE_FAIL,
} from '../constants/retentionConstants'

export const retentionRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case RETENTION_REGISTER_REQUEST:
      return { loadingRetentionRegister: true }
    case RETENTION_REGISTER_SUCCESS:
      return {
        successRetentionRegister: true,
        loadingRetentionRegister: false,
        retentionRegisterData: action.payload,
      }
    case RETENTION_REGISTER_FAIL:
      return {
        loadingRetentionRegister: false,
        errorRetentionRegister: action.payload,
      }
    case RETENTION_REGISTER_RESET:
      return {}
    default:
      return state
  }
}
export const retentionListReducer = (state = {}, action) => {
  switch (action.type) {
    case RETENTION_LIST_REQUEST:
      return { loadingRetentionList: true }
    case RETENTION_LIST_SUCCESS:
      return {
        successRetentionList: true,
        loadingRetentionList: false,
        retentionListData: action.payload,
      }
    case RETENTION_LIST_FAIL:
      return {
        loadingRetentionList: false,
        errorRetentionList: action.payload,
      }
    case RETENTION_LIST_RESET:
      return {}
    default:
      return state
  }
}
export const retentionUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case RETENTION_UPDATE_REQUEST:
      return { loadingRetentionUpdate: true }
    case RETENTION_UPDATE_SUCCESS:
      return {
        successRetentionUpdate: true,
        loadingRetentionUpdate: false,
        retentionUpdateData: action.payload,
      }
    case RETENTION_UPDATE_FAIL:
      return {
        loadingRetentionUpdate: false,
        errorRetentionUpdate: action.payload,
      }
    case RETENTION_UPDATE_RESET:
      return {}
    default:
      return state
  }
}
export const retentionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case RETENTION_DELETE_REQUEST:
      return { loadingRetentionDelete: true }
    case RETENTION_DELETE_SUCCESS:
      return {
        successRetentionDelete: true,
        loadingRetentionDelete: false,
        retentionDeleteData: action.payload,
      }
    case RETENTION_DELETE_FAIL:
      return {
        loadingRetentionDelete: false,
        errorRetentionDelete: action.payload,
      }
    case RETENTION_DELETE_RESET:
      return {}
    default:
      return state
  }
}
