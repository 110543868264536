import {
  PRESCRIBER_LIST_REQUEST,
  PRESCRIBER_LIST_SUCCESS,
  PRESCRIBER_LIST_FAIL,
  PRESCRIBER_LIST_RESET,
  PRESCRIBER_REFERER_LIST_BY_ID_REQUEST,
  PRESCRIBER_REFERER_LIST_BY_ID_SUCCESS,
  PRESCRIBER_REFERER_LIST_BY_ID_FAIL,
  PRESCRIBER_REFERER_LIST_BY_ID_RESET,
  PRESCRIBER_PROFILE_LIST_BY_ID_REQUEST,
  PRESCRIBER_PROFILE_LIST_BY_ID_SUCCESS,
  PRESCRIBER_PROFILE_LIST_BY_ID_FAIL,
  PRESCRIBER_PROFILE_LIST_BY_ID_RESET,
} from '../constants/prescriberConstants'

export const prescriberListReducer = (state = {}, action) => {
  switch (action.type) {
    case PRESCRIBER_LIST_REQUEST:
      return { loadingPrescriberList: true }
    case PRESCRIBER_LIST_SUCCESS:
      return { loadingPrescriberList: false, successPrescriberList: true, prescriberListData: action.payload }
    case PRESCRIBER_LIST_FAIL:
      return { loadingPrescriberList: false, errorPrescriberList: action.payload }
    case PRESCRIBER_LIST_RESET:
      return {}
    default:
      return state
  }
}
export const prescriberRefererListByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case PRESCRIBER_REFERER_LIST_BY_ID_REQUEST:
      return { loadingPrescriberRefererListById: true }
    case PRESCRIBER_REFERER_LIST_BY_ID_SUCCESS:
      return {
        loadingPrescriberRefererListById: false,
        successPrescriberRefererListById: true,
        prescriberRefererListByIdData: action.payload,
      }
    case PRESCRIBER_REFERER_LIST_BY_ID_FAIL:
      return { loadingPrescriberRefererListById: false, errorPrescriberRefererListById: action.payload }
    case PRESCRIBER_REFERER_LIST_BY_ID_RESET:
      return {}
    default:
      return state
  }
}
export const prescriberProfileListByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case PRESCRIBER_PROFILE_LIST_BY_ID_REQUEST:
      return { loadingPrescriberProfileListById: true }
    case PRESCRIBER_PROFILE_LIST_BY_ID_SUCCESS:
      return {
        loadingPrescriberProfileListById: false,
        successPrescriberProfileListById: true,
        prescribers: action.payload,
      }
    case PRESCRIBER_PROFILE_LIST_BY_ID_FAIL:
      return { loadingPrescriberProfileListById: false, errorPrescriberProfileListById: action.payload }
    case PRESCRIBER_PROFILE_LIST_BY_ID_RESET:
      return {}
    default:
      return state
  }
}
