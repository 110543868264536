export const INVESTOR_PROFILE_REGISTER_REQUEST = 'INVESTOR_PROFILE_REGISTER_REQUEST'
export const INVESTOR_PROFILE_REGISTER_SUCCESS = 'INVESTOR_PROFILE_REGISTER_SUCCESS'
export const INVESTOR_PROFILE_REGISTER_FAIL = 'INVESTOR_PROFILE_REGISTER_FAIL'
export const INVESTOR_PROFILE_REGISTER_RESET = 'INVESTOR_PROFILE_REGISTER_RESET'

export const INVESTOR_PROFILE_BY_ADMIN_REGISTER_REQUEST = 'INVESTOR_PROFILE_BY_ADMIN_REGISTER_REQUEST'
export const INVESTOR_PROFILE_BY_ADMIN_REGISTER_SUCCESS = 'INVESTOR_PROFILE_BY_ADMIN_REGISTER_SUCCESS'
export const INVESTOR_PROFILE_BY_ADMIN_REGISTER_FAIL = 'INVESTOR_PROFILE_BY_ADMIN_REGISTER_FAIL'
export const INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET = 'INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET'

export const INVESTORS_PROFILE_LIST_REQUEST = 'INVESTORS_PROFILE_LIST_REQUEST'
export const INVESTORS_PROFILE_LIST_SUCCESS = 'INVESTORS_PROFILE_LIST_SUCCESS'
export const INVESTORS_PROFILE_LIST_FAIL = 'INVESTORS_PROFILE_LIST_FAIL'
export const INVESTORS_PROFILE_LIST_RESET = 'INVESTORS_PROFILE_LIST_RESET'

export const INVESTORS_PROFILE_PAGINATION_LIST_REQUEST = 'INVESTORS_PROFILE_PAGINATION_LIST_REQUEST'
export const INVESTORS_PROFILE_PAGINATION_LIST_SUCCESS = 'INVESTORS_PROFILE_PAGINATION_LIST_SUCCESS'
export const INVESTORS_PROFILE_PAGINATION_LIST_FAIL = 'INVESTORS_PROFILE_PAGINATION_LIST_FAIL'
export const INVESTORS_PROFILE_PAGINATION_LIST_RESET = 'INVESTORS_PROFILE_PAGINATION_LIST_RESET'

export const INVESTORS_PROFILE_LIST_FOR_PDL_REQUEST = 'INVESTORS_PROFILE_LIST_FOR_PDL_REQUEST'
export const INVESTORS_PROFILE_LIST_FOR_PDL_SUCCESS = 'INVESTORS_PROFILE_LIST_FOR_PDL_SUCCESS'
export const INVESTORS_PROFILE_LIST_FOR_PDL_FAIL = 'INVESTORS_PROFILE_LIST_FOR_PDL_FAIL'
export const INVESTORS_PROFILE_LIST_FOR_PDL_RESET = 'INVESTORS_PROFILE_LIST_FOR_PDL_RESET'

export const INVESTORS_PROFILE_BY_USER_REQUEST = 'INVESTORS_PROFILE_BY_USER_REQUEST'
export const INVESTORS_PROFILE_BY_USER_SUCCESS = 'INVESTORS_PROFILE_BY_USER_SUCCESS'
export const INVESTORS_PROFILE_BY_USER_FAIL = 'INVESTORS_PROFILE_BY_USER_FAIL'
export const INVESTORS_PROFILE_BY_USER_RESET = 'INVESTORS_PROFILE_BY_USER_RESET'

export const INVESTOR_PROFILE_UPDATE_REQUEST = 'INVESTOR_PROFILE_UPDATE_REQUEST'
export const INVESTOR_PROFILE_UPDATE_SUCCESS = 'INVESTOR_PROFILE_UPDATE_SUCCESS'
export const INVESTOR_PROFILE_UPDATE_FAIL = 'INVESTOR_PROFILE_UPDATE_FAIL'
export const INVESTOR_PROFILE_UPDATE_RESET = 'INVESTOR_PROFILE_UPDATE_RESET'

export const INVESTOR_PROFILE_WITH_USER_REGISTER_REQUEST = 'INVESTOR_PROFILE_WITH_USER_REGISTER_REQUEST'
export const INVESTOR_PROFILE_WITH_USER_REGISTER_SUCCESS = 'INVESTOR_PROFILE_WITH_USER_REGISTER_SUCCESS'
export const INVESTOR_PROFILE_WITH_USER_REGISTER_FAIL = 'INVESTOR_PROFILE_WITH_USER_REGISTER_FAIL'
export const INVESTOR_PROFILE_WITH_USER_REGISTER_RESET = 'INVESTOR_PROFILE_WITH_USER_REGISTER_RESET'

export const INVESTOR_PROFILE_BY_ID_REQUEST = 'INVESTOR_PROFILE_BY_ID_REQUEST'
export const INVESTOR_PROFILE_BY_ID_SUCCESS = 'INVESTOR_PROFILE_BY_ID_SUCCESS'
export const INVESTOR_PROFILE_BY_ID_FAIL = 'INVESTOR_PROFILE_BY_ID_FAIL'
export const INVESTOR_PROFILE_BY_ID_RESET = 'INVESTOR_PROFILE_BY_ID_RESET'

export const INVESTOR_PROFILE_DELETE_REQUEST = 'INVESTOR_PROFILE_DELETE_REQUEST'
export const INVESTOR_PROFILE_DELETE_SUCCESS = 'INVESTOR_PROFILE_DELETE_SUCCESS'
export const INVESTOR_PROFILE_DELETE_FAIL = 'INVESTOR_PROFILE_DELETE_FAIL'
export const INVESTOR_PROFILE_DELETE_RESET = 'INVESTOR_PROFILE_DELETE_RESET'

export const INVESTOR_PROFILE_UPLOAD_DOCUMENTS_REQUEST = 'INVESTOR_PROFILE_UPLOAD_DOCUMENTS_REQUEST'
export const INVESTOR_PROFILE_UPLOAD_DOCUMENTS_SUCCESS = 'INVESTOR_PROFILE_UPLOAD_DOCUMENTS_SUCCESS'
export const INVESTOR_PROFILE_UPLOAD_DOCUMENTS_FAIL = 'INVESTOR_PROFILE_UPLOAD_DOCUMENTS_FAIL'
export const INVESTOR_PROFILE_UPLOAD_DOCUMENTS_RESET = 'INVESTOR_PROFILE_UPLOAD_DOCUMENTS_RESET'

export const INVESTOR_PROFILE_DELETE_DOCUMENT_REQUEST = 'INVESTOR_PROFILE_DELETE_DOCUMENT_REQUEST'
export const INVESTOR_PROFILE_DELETE_DOCUMENT_SUCCESS = 'INVESTOR_PROFILE_DELETE_DOCUMENT_SUCCESS'
export const INVESTOR_PROFILE_DELETE_DOCUMENT_FAIL = 'INVESTOR_PROFILE_DELETE_DOCUMENT_FAIL'
export const INVESTOR_PROFILE_DELETE_DOCUMENT_RESET = 'INVESTOR_PROFILE_DELETE_DOCUMENT_RESET'
