import {
  END_OF_PROJECT_DOCUMENT_REGISTER_REQUEST,
  END_OF_PROJECT_DOCUMENT_REGISTER_SUCCESS,
  END_OF_PROJECT_DOCUMENT_REGISTER_FAIL,
  END_OF_PROJECT_DOCUMENT_REGISTER_RESET,
  END_OF_PROJECT_DOCUMENT_DELETE_REQUEST,
  END_OF_PROJECT_DOCUMENT_DELETE_SUCCESS,
  END_OF_PROJECT_DOCUMENT_DELETE_FAIL,
  END_OF_PROJECT_DOCUMENT_DELETE_RESET,
} from '../constants/endOfProjectDocumentConstants'

export const endOfProjectDocumentRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case END_OF_PROJECT_DOCUMENT_REGISTER_REQUEST:
      return { loadingEndOfProjectDocumentRegister: true }
    case END_OF_PROJECT_DOCUMENT_REGISTER_SUCCESS:
      return {
        successEndOfProjectDocumentRegister: true,
        loadingEndOfProjectDocumentRegister: false,
        endOfProjectDocumentRegisterData: action.payload,
      }
    case END_OF_PROJECT_DOCUMENT_REGISTER_FAIL:
      return {
        loadingEndOfProjectDocumentRegister: false,
        errorEndOfProjectDocumentRegister: action.payload,
      }
    case END_OF_PROJECT_DOCUMENT_REGISTER_RESET:
      return {}
    default:
      return state
  }
}
export const endOfProjectDocumentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case END_OF_PROJECT_DOCUMENT_DELETE_REQUEST:
      return { loadingEndOfProjectDocumentDelete: true }
    case END_OF_PROJECT_DOCUMENT_DELETE_SUCCESS:
      return {
        successEndOfProjectDocumentDelete: true,
        loadingEndOfProjectDocumentDelete: false,
        endOfProjectDocumentDeleteData: action.payload,
      }
    case END_OF_PROJECT_DOCUMENT_DELETE_FAIL:
      return {
        loadingEndOfProjectDocumentDelete: false,
        errorEndOfProjectDocumentDelete: action.payload,
      }
    case END_OF_PROJECT_DOCUMENT_DELETE_RESET:
      return {}
    default:
      return state
  }
}
