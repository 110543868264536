import {
  INVESTMENT_TREE_LIST_BY_USER_REQUEST,
  INVESTMENT_TREE_LIST_BY_USER_SUCCESS,
  INVESTMENT_TREE_LIST_BY_USER_FAIL,
  INVESTMENT_TREE_LIST_BY_USER_RESET,
  INVESTMENT_TREE_BY_ID_REQUEST,
  INVESTMENT_TREE_BY_ID_SUCCESS,
  INVESTMENT_TREE_BY_ID_FAIL,
  INVESTMENT_TREE_BY_ID_RESET
} from '../constants/investmentTreeConstants'

export const investmentTreeListByUserReducer = (state = {}, action) => {
  switch (action.type) {
    case INVESTMENT_TREE_LIST_BY_USER_REQUEST:
      return { loadingInvestmentTreeListByUser: true }
    case INVESTMENT_TREE_LIST_BY_USER_SUCCESS:
      return {
        successInvestmentTreeListByUser: true,
        loadingInvestmentTreeListByUser: false,
        investmentTreeListByUserData: action.payload,
      }
    case INVESTMENT_TREE_LIST_BY_USER_FAIL:
      return {
        loadingInvestmentTreeListByUser: false,
        errorInvestmentTreeListByUser: action.payload,
      }
    case INVESTMENT_TREE_LIST_BY_USER_RESET:
      return {}
    default:
      return state
  }
}

export const investmentTreeByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case INVESTMENT_TREE_BY_ID_REQUEST:
      return { loadingInvestmentTreeById: true }
    case INVESTMENT_TREE_BY_ID_SUCCESS:
      return {
        successInvestmentTreeById: true,
        loadingInvestmentTreeById: false,
        investmentTreeByIdData: action.payload,
      }
    case INVESTMENT_TREE_BY_ID_FAIL:
      return {
        loadingInvestmentTreeById: false,
        errorInvestmentTreeById: action.payload,
      }
    case INVESTMENT_TREE_BY_ID_RESET:
      return {}
    default:
      return state
  }
}
