import * as types from '../constants/investmentCommentConstants'

export const investmentCommentRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_COMMENT_REGISTER_REQUEST:
			return { loadingInvestmentCommentRegister: true }
		case types.INVESTMENT_COMMENT_REGISTER_SUCCESS:
			return {
				successInvestmentCommentRegister: true,
				loadingInvestmentCommentRegister: false,
				investmentCommentRegisterData: action.payload,
			}
		case types.INVESTMENT_COMMENT_REGISTER_FAIL:
			return {
				loadingInvestmentCommentRegister: false,
				errorInvestmentCommentRegister: action.payload,
			}
		case types.INVESTMENT_COMMENT_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const investmentCommentsByInvestmentIdReducer = (state = {}, action) => {
	switch (action.type) {
		case types.INVESTMENT_COMMENT_BY_ID_LIST_REQUEST:
			return { loadingInvestmentCommentsByInvestmentId: true }
		case types.INVESTMENT_COMMENT_BY_ID_LIST_SUCCESS:
			return {
				successinvestmentCommentsByInvestmentId: true,
				loadingInvestmentCommentsByInvestmentId: false,
				investmentCommentsByInvestmentIdList: action.payload,
			}
		case types.INVESTMENT_COMMENT_BY_ID_LIST_FAIL:
			return {
				loadingInvestmentCommentsByInvestmentId: false,
				errorInvestmentCommentsByInvestmentId: action.payload,
			}
		case types.INVESTMENT_COMMENT_BY_ID_LIST_RESET:
			return {}
		default:
			return state
	}
}
