import {
  OWNER_LIST_FAIL,
  OWNER_LIST_RESET,
  OWNER_LIST_SUCCESS,
  OWNER_LIST_REQUEST,
} from 'local_redux/constants/ownersProfileConstants'

export const ownersGetUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case OWNER_LIST_REQUEST:
      return { loadingOwnerGetUsers: true }
    case OWNER_LIST_SUCCESS:
      return {
        successOwnerGetUsers: true,
        loadingOwnerGetUsers: false,
        ownerGetUsersData: action.payload,
      }
    case OWNER_LIST_FAIL:
      return {
        loadingOwnerGetUsers: false,
        errorOwnerGetUsersData: action.payload,
      }
    case OWNER_LIST_RESET:
      return {}
    default:
      return state
  }
}
