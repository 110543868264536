import {
	INVESTOR_PROFILE_REGISTER_REQUEST,
	INVESTOR_PROFILE_REGISTER_SUCCESS,
	INVESTOR_PROFILE_REGISTER_FAIL,
	INVESTOR_PROFILE_REGISTER_RESET,
	INVESTOR_PROFILE_BY_ADMIN_REGISTER_REQUEST,
	INVESTOR_PROFILE_BY_ADMIN_REGISTER_SUCCESS,
	INVESTOR_PROFILE_BY_ADMIN_REGISTER_FAIL,
	INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET,
	INVESTORS_PROFILE_BY_USER_REQUEST,
	INVESTORS_PROFILE_BY_USER_SUCCESS,
	INVESTORS_PROFILE_BY_USER_FAIL,
	INVESTORS_PROFILE_BY_USER_RESET,
	INVESTOR_PROFILE_UPDATE_REQUEST,
	INVESTOR_PROFILE_UPDATE_SUCCESS,
	INVESTOR_PROFILE_UPDATE_FAIL,
	INVESTOR_PROFILE_UPDATE_RESET,
	INVESTOR_PROFILE_WITH_USER_REGISTER_REQUEST,
	INVESTOR_PROFILE_WITH_USER_REGISTER_SUCCESS,
	INVESTOR_PROFILE_WITH_USER_REGISTER_FAIL,
	INVESTOR_PROFILE_WITH_USER_REGISTER_RESET,
	INVESTOR_PROFILE_DELETE_REQUEST,
	INVESTOR_PROFILE_DELETE_SUCCESS,
	INVESTOR_PROFILE_DELETE_FAIL,
	INVESTOR_PROFILE_DELETE_RESET,
	INVESTORS_PROFILE_LIST_REQUEST,
	INVESTORS_PROFILE_LIST_SUCCESS,
	INVESTORS_PROFILE_LIST_FAIL,
	INVESTORS_PROFILE_LIST_RESET,
	INVESTOR_PROFILE_BY_ID_REQUEST,
	INVESTOR_PROFILE_BY_ID_SUCCESS,
	INVESTOR_PROFILE_BY_ID_FAIL,
	INVESTOR_PROFILE_BY_ID_RESET,
	INVESTOR_PROFILE_UPLOAD_DOCUMENTS_REQUEST,
	INVESTOR_PROFILE_UPLOAD_DOCUMENTS_SUCCESS,
	INVESTOR_PROFILE_UPLOAD_DOCUMENTS_FAIL,
	INVESTOR_PROFILE_UPLOAD_DOCUMENTS_RESET,
	INVESTOR_PROFILE_DELETE_DOCUMENT_REQUEST,
	INVESTOR_PROFILE_DELETE_DOCUMENT_SUCCESS,
	INVESTOR_PROFILE_DELETE_DOCUMENT_FAIL,
	INVESTOR_PROFILE_DELETE_DOCUMENT_RESET,
	INVESTORS_PROFILE_LIST_FOR_PDL_FAIL,
	INVESTORS_PROFILE_LIST_FOR_PDL_REQUEST,
	INVESTORS_PROFILE_LIST_FOR_PDL_SUCCESS,
	INVESTORS_PROFILE_LIST_FOR_PDL_RESET,
	INVESTORS_PROFILE_PAGINATION_LIST_REQUEST,
	INVESTORS_PROFILE_PAGINATION_LIST_SUCCESS,
	INVESTORS_PROFILE_PAGINATION_LIST_FAIL,
	INVESTORS_PROFILE_PAGINATION_LIST_RESET,
} from '../constants/investorProfileConstants'

export const investorProfileRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_PROFILE_REGISTER_REQUEST:
			return { loadingInvestorProfileRegister: true }
		case INVESTOR_PROFILE_REGISTER_SUCCESS:
			return {
				successInvestorProfileRegister: true,
				loadingInvestorProfileRegister: false,
				investorProfileRegister: action.payload,
			}
		case INVESTOR_PROFILE_REGISTER_FAIL:
			return {
				loadingInvestorProfileRegister: false,
				errorInvestorProfileRegister: action.payload,
			}
		case INVESTOR_PROFILE_REGISTER_RESET:
			return {}
		default:
			return state
	}
}
export const investorProfileByAdminRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_PROFILE_BY_ADMIN_REGISTER_REQUEST:
			return { loadingInvestorProfileByAdminRegister: true }
		case INVESTOR_PROFILE_BY_ADMIN_REGISTER_SUCCESS:
			return {
				successInvestorProfileByAdminRegister: true,
				loadingInvestorProfileByAdminRegister: false,
				investorProfileByAdminRegisterData: action.payload,
			}
		case INVESTOR_PROFILE_BY_ADMIN_REGISTER_FAIL:
			return {
				loadingInvestorProfileByAdminRegister: false,
				errorInvestorProfileByAdminRegister: action.payload,
			}
		case INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET:
			return {}
		default:
			return state
	}
}
export const investorsProfileByUserReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTORS_PROFILE_BY_USER_REQUEST:
			return { loadingInvestorsProfileByUser: true }
		case INVESTORS_PROFILE_BY_USER_SUCCESS:
			return {
				successInvestorsProfileByUser: true,
				loadingInvestorsProfileByUser: false,
				investorsProfileByUserData: action.payload,
			}
		case INVESTORS_PROFILE_BY_USER_FAIL:
			return {
				loadingInvestorsProfileByUser: false,
				errorInvestorsProfileByUser: action.payload,
			}
		case INVESTORS_PROFILE_BY_USER_RESET:
			return {}
		default:
			return state
	}
}
export const investorsProfileListReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTORS_PROFILE_LIST_REQUEST:
			return { loadingInvestorsProfileList: true }
		case INVESTORS_PROFILE_LIST_SUCCESS:
			return {
				successInvestorsProfileList: true,
				loadingInvestorsProfileList: false,
				investorsProfileListData: action.payload,
			}
		case INVESTORS_PROFILE_LIST_FAIL:
			return {
				loadingInvestorsProfileList: false,
				errorInvestorsProfileList: action.payload,
			}
		case INVESTORS_PROFILE_LIST_RESET:
			return {}
		default:
			return state
	}
}
export const investorsProfilePaginationListReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTORS_PROFILE_PAGINATION_LIST_REQUEST:
			return { loadingInvestorsProfileList: true }
		case INVESTORS_PROFILE_PAGINATION_LIST_SUCCESS:
			return {
				successInvestorsProfilePaginationList: true,
				loadingInvestorsProfilePaginationList: false,
				investorsProfilePaginationListData: action.payload,
			}
		case INVESTORS_PROFILE_PAGINATION_LIST_FAIL:
			return {
				loadingInvestorsProfilePaginationList: false,
				errorInvestorsProfilePaginationList: action.payload,
			}
		case INVESTORS_PROFILE_PAGINATION_LIST_RESET:
			return {}
		default:
			return state
	}
}
export const investorsProfileListForPDLReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTORS_PROFILE_LIST_FOR_PDL_REQUEST:
			return { loadingInvestorsProfilesForPDL: true }
		case INVESTORS_PROFILE_LIST_FOR_PDL_SUCCESS:
			return {
				successInvestorsProfilesForPDL: true,
				loadingInvestorsProfilesForPDL: false,
				profilesForPDL: action.payload,
			}
		case INVESTORS_PROFILE_LIST_FOR_PDL_FAIL:
			return {
				loadingInvestorsProfilesForPDL: false,
				errorInvestorsProfilesForPDL: action.payload,
			}
		case INVESTORS_PROFILE_LIST_FOR_PDL_RESET:
			return {}
		default:
			return state
	}
}

export const investorProfileUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_PROFILE_UPDATE_REQUEST:
			return { loadingInvestorProfileUpdate: true }
		case INVESTOR_PROFILE_UPDATE_SUCCESS:
			return {
				successInvestorProfileUpdate: true,
				loadingInvestorProfileUpdate: false,
				investorProfileUpdateData: action.payload,
			}
		case INVESTOR_PROFILE_UPDATE_FAIL:
			return {
				loadingInvestorProfileUpdate: false,
				errorInvestorProfileUpdate: action.payload,
			}
		case INVESTOR_PROFILE_UPDATE_RESET:
			return {}
		default:
			return state
	}
}
export const investorProfileWithUserRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_PROFILE_WITH_USER_REGISTER_REQUEST:
			return { loadingInvestorProfileWithUserRegister: true }
		case INVESTOR_PROFILE_WITH_USER_REGISTER_SUCCESS:
			return {
				successInvestorProfileWithUserRegister: true,
				loadingInvestorProfileWithUserRegister: false,
				investorProfileWithUserRegisterData: action.payload,
			}
		case INVESTOR_PROFILE_WITH_USER_REGISTER_FAIL:
			return {
				loadingInvestorProfileWithUserRegister: false,
				errorInvestorProfileWithUserRegister: action.payload,
			}
		case INVESTOR_PROFILE_WITH_USER_REGISTER_RESET:
			return {}
		default:
			return state
	}
}
export const investorProfileDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_PROFILE_DELETE_REQUEST:
			return { loadingInvestorProfileDelete: true }
		case INVESTOR_PROFILE_DELETE_SUCCESS:
			return {
				successInvestorProfileDelete: true,
				loadingInvestorProfileDelete: false,
				investorProfileDeleteData: action.payload,
			}
		case INVESTOR_PROFILE_DELETE_FAIL:
			return {
				loadingInvestorProfileDelete: false,
				errorInvestorProfileDelete: action.payload,
			}
		case INVESTOR_PROFILE_DELETE_RESET:
			return {}
		default:
			return state
	}
}
export const investorProfileByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_PROFILE_BY_ID_REQUEST:
			return { loadingInvestorProfileById: true }
		case INVESTOR_PROFILE_BY_ID_SUCCESS:
			return {
				successInvestorProfileById: true,
				loadingInvestorProfileById: false,
				investorProfileByIdData: action.payload,
			}
		case INVESTOR_PROFILE_BY_ID_FAIL:
			return {
				loadingInvestorProfileById: false,
				errorInvestorProfileById: action.payload,
			}
		case INVESTOR_PROFILE_BY_ID_RESET:
			return {}
		default:
			return state
	}
}
export const investorProfileUploadDocumentsReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_PROFILE_UPLOAD_DOCUMENTS_REQUEST:
			return { loadingInvestorProfileUploadDocuments: true }
		case INVESTOR_PROFILE_UPLOAD_DOCUMENTS_SUCCESS:
			return {
				successInvestorProfileUploadDocuments: true,
				loadingInvestorProfileUploadDocuments: false,
				investorProfileUploadDocumentsData: action.payload,
			}
		case INVESTOR_PROFILE_UPLOAD_DOCUMENTS_FAIL:
			return {
				loadingInvestorProfileUploadDocuments: false,
				errorInvestorProfileUploadDocuments: action.payload,
			}
		case INVESTOR_PROFILE_UPLOAD_DOCUMENTS_RESET:
			return {}
		default:
			return state
	}
}
export const investorProfileDeleteDocumentReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_PROFILE_DELETE_DOCUMENT_REQUEST:
			return { loadingInvestorProfileDeleteDocument: true }
		case INVESTOR_PROFILE_DELETE_DOCUMENT_SUCCESS:
			return {
				successInvestorProfileDeleteDocument: true,
				loadingInvestorProfileDeleteDocument: false,
				investorProfileDeleteDocumentData: action.payload,
			}
		case INVESTOR_PROFILE_DELETE_DOCUMENT_FAIL:
			return {
				loadingInvestorProfileDeleteDocument: false,
				errorInvestorProfileDeleteDocument: action.payload,
			}
		case INVESTOR_PROFILE_DELETE_DOCUMENT_RESET:
			return {}
		default:
			return state
	}
}
