import * as types from '../constants/projectConstant'

export const projectRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_REGISTER_REQUEST:
			return { loadingProjectRegister: true }
		case types.PROJECT_REGISTER_SUCCESS:
			return {
				successProjectRegister: true,
				loadingProjectRegister: false,
				projectRegisterData: action.payload,
			}
		case types.PROJECT_REGISTER_FAIL:
			return {
				loadingProjectRegister: false,
				errorProjectRegister: action.payload,
			}
		case types.PROJECT_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const projectListReducer = (state = { loadingProjectList: true }, action) => {
	switch (action.type) {
		case types.PROJECT_LIST_REQUEST:
			return { loadingProjectList: true }
		case types.PROJECT_LIST_SUCCESS:
			return {
				successProjectList: true,
				loadingProjectList: false,
				projectListData: action.payload,
			}
		case types.PROJECT_LIST_FAIL:
			return {
				loadingProjectList: false,
				errorProjectList: action.payload,
			}
		case types.PROJECT_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const projectFinishedListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_FINISHED_LIST_REQUEST:
			return { loadingProjectFinishedList: true }
		case types.PROJECT_FINISHED_LIST_SUCCESS:
			return {
				successProjectFinishedList: true,
				loadingProjectFinishedList: false,
				projectFinishedListData: action.payload,
			}
		case types.PROJECT_FINISHED_LIST_FAIL:
			return {
				loadingProjectFinishedList: false,
				errorProjectFinishedList: action.payload,
			}
		case types.PROJECT_FINISHED_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const sendProjectSettlementEmailReducer = (state = {}, action) => {
	switch (action.type) {
		case types.SEND_PROJECT_SETTLEMENT_EMAIL_REQUEST:
			return { loadingSendProjectSettlementEmail: true }
		case types.SEND_PROJECT_SETTLEMENT_EMAIL_SUCCESS:
			return {
				successSendProjectSettlementEmail: true,
				loadingSendProjectSettlementEmail: false,
				sendProjectSettlementEmailData: action.payload,
			}
		case types.SEND_PROJECT_SETTLEMENT_EMAIL_FAIL:
			return {
				loadingSendProjectSettlementEmail: false,
				errorSendProjectSettlementEmail: action.payload,
			}
		case types.SEND_PROJECT_SETTLEMENT_EMAIL_RESET:
			return {}
		default:
			return state
	}
}

export const projectListToInvestReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_TO_INVEST_LIST_REQUEST:
			return { loadingProjectList: true }
		case types.PROJECT_TO_INVEST_LIST_SUCCESS:
			return {
				successProjectList: true,
				loadingProjectList: false,
				projectListData: action.payload,
			}
		case types.PROJECT_TO_INVEST_LIST_FAIL:
			return {
				loadingProjectList: false,
				errorProjectList: action.payload,
			}
		case types.PROJECT_TO_INVEST_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const projectsFromSalesReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_FROM_SALES_LIST_REQUEST:
			return { loadingProjectFromSalesList: true }
		case types.PROJECT_FROM_SALES_LIST_SUCCESS:
			return {
				loadingProjectFromSalesList: false,
				successProjectFromSalesList: true,
				projectFromSalesList: action.payload,
			}
		case types.PROJECT_FROM_SALES_LIST_FAIL:
			return {
				loadingProjectFromSalesList: false,
				errorProjectFromSalesList: action.payload,
			}
		case types.PROJECT_FROM_SALES_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const projectsToPdlLiquidateListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_FROM_PDL_LIQUIDATE_LIST_REQUEST:
			return { loadingProjectsToPdlLiquidate: true }
		case types.PROJECT_FROM_PDL_LIQUIDATE_LIST_SUCCESS:
			return {
				loadingProjectsToPdlLiquidate: false,
				successProjectsToPdlLiquidate: true,
				projectsToPdlLiquidate: action.payload,
			}
		case types.PROJECT_FROM_PDL_LIQUIDATE_LIST_FAIL:
			return {
				loadingProjectsToPdlLiquidate: false,
				errorProjectsToPdlLiquidate: action.payload,
			}
		case types.PROJECT_FROM_PDL_LIQUIDATE_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const projectUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_UPDATE_REQUEST:
			return { loadingProjectUpdate: true }
		case types.PROJECT_UPDATE_SUCCESS:
			return {
				successProjectUpdate: true,
				loadingProjectUpdate: false,
				projectUpdateData: action.payload,
			}
		case types.PROJECT_UPDATE_FAIL:
			return {
				loadingProjectUpdate: false,
				errorProjectUpdate: action.payload,
			}
		case types.PROJECT_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const projectDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_DELETE_REQUEST:
			return { loadingProjectDelete: true }
		case types.PROJECT_DELETE_SUCCESS:
			return {
				successProjectDelete: true,
				loadingProjectDelete: false,
				projectDeleteData: action.payload,
			}
		case types.PROJECT_DELETE_FAIL:
			return {
				loadingProjectDelete: false,
				errorProjectDelete: action.payload,
			}
		case types.PROJECT_DELETE_RESET:
			return {}
		default:
			return state
	}
}

export const projectByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_BY_ID_REQUEST:
			return { loadingProjectById: true }
		case types.PROJECT_BY_ID_SUCCESS:
			return {
				successProjectById: true,
				loadingProjectById: false,
				projectByIdData: action.payload,
			}
		case types.PROJECT_BY_ID_FAIL:
			return {
				loadingProjectById: false,
				errorProjectById: action.payload,
			}
		case types.PROJECT_BY_ID_RESET:
			return {}
		default:
			return state
	}
}

export const projectRebuildCsvReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_REBUILD_CSV_REQUEST:
			return { loadingProjectRebuildCsv: true }
		case types.PROJECT_REBUILD_CSV_SUCCESS:
			return {
				successProjectRebuildCsv: true,
				loadingProjectRebuildCsv: false,
				projectRebuildCsvData: action.payload,
			}
		case types.PROJECT_REBUILD_CSV_FAIL:
			return {
				loadingProjectRebuildCsv: false,
				errorProjectRebuildCsv: action.payload,
			}
		case types.PROJECT_REBUILD_CSV_RESET:
			return {}
		default:
			return state
	}
}

export const projectsDelayedListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_DELAYED_LIST_REQUEST:
			return { loadingProjectsDelayedList: true }
		case types.PROJECT_DELAYED_LIST_SUCCESS:
			return {
				successProjectsDelayedList: true,
				loadingProjectsDelayedList: false,
				projectsDelayedListData: action.payload,
			}
		case types.PROJECT_DELAYED_LIST_FAIL:
			return {
				loadingProjectsDelayedList: false,
				errorProjectsDelayedList: action.payload,
			}
		case types.PROJECT_DELAYED_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const reasonsFromProjectByProjectIdListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_REQUEST:
			return { loadingReasonsListByProjectIdList: true }
		case types.REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_SUCCESS:
			return {
				successReasonsListByProjectIdList: true,
				loadingReasonsListByProjectIdList: false,
				reasonsListByProjectIdListData: action.payload,
			}
		case types.REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_FAIL:
			return {
				loadingReasonsListByProjectIdList: false,
				errorReasonsListByProjectIdList: action.payload,
			}
		case types.REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const projectsUpdateFinishedDateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_UPDATE_FINISHED_DATE_REQUEST:
			return { loadingProjectsUpdateFinishedDate: true }
		case types.PROJECT_UPDATE_FINISHED_DATE_SUCCESS:
			return {
				successProjectsUpdateFinishedDate: true,
				loadingProjectsUpdateFinishedDate: false,
				projectsUpdateFinishedDateData: action.payload,
			}
		case types.PROJECT_UPDATE_FINISHED_DATE_FAIL:
			return {
				loadingProjectsUpdateFinishedDate: false,
				errorProjectsUpdateFinishedDate: action.payload,
			}
		case types.PROJECT_UPDATE_FINISHED_DATE_RESET:
			return {}
		default:
			return state
	}
}

export const sigProjectUpdatebyPdlReducer = (state = {}, action) => {
	switch (action.type) {
		case types.SIG_PROJECT_UPDATE_BY_PDL_REQUEST:
			return { loadingSigProjectUpdate: true }
		case types.SIG_PROJECT_UPDATE_BY_PDL_SUCCESS:
			return {
				successSigProjectUpdate: true,
				loadingSigProjectUpdate: false,
				sigProjectUpdateByPdl: action.payload,
			}
		case types.SIG_PROJECT_UPDATE_BY_PDL_FAIL:
			return {
				loadingSigProjectUpdate: false,
				errorSigProjectUpdate: action.payload,
			}
		case types.SIG_PROJECT_UPDATE_BY_PDL_RESET:
			return {}
		default:
			return state
	}
}

export const projectsWithPDLToInvestReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_PDL_TO_INVEST_LIST_REQUEST:
			return { loadingPDLProjectsToInvestList: true }
		case types.PROJECT_PDL_TO_INVEST_LIST_SUCCESS:
			return {
				successPDLProjectsToInvestList: true,
				loadingPDLProjectsToInvestList: false,
				PDLProjectsToInvestListData: action.payload,
			}
		case types.PROJECT_PDL_TO_INVEST_LIST_FAIL:
			return {
				loadingPDLProjectsToInvestList: false,
				errorPDLProjectsToInvestList: action.payload,
			}
		case types.PROJECT_PDL_TO_INVEST_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const getAcceptedProjectOfferReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_ACCEPTED_OFFER_REQUEST:
			return { loadingProjectAcceptedOffer: true }
		case types.PROJECT_ACCEPTED_OFFER_SUCCESS:
			return {
				loadingProjectAcceptedOffer: false,
				successProjectAcceptedOffer: true,
				acceptedOffer: action.payload,
			}
		case types.PROJECT_ACCEPTED_OFFER_FAIL:
			return {
				loadingProjectAcceptedOffer: false,
				errorProjectAcceptedOffer: action.payload,
			}
		case types.PROJECT_ACCEPTED_OFFER_RESET:
			return {}
		default:
			return state
	}
}
