import {
	INVESTOR_REGISTER_REQUEST,
	INVESTOR_REGISTER_SUCCESS,
	INVESTOR_REGISTER_FAIL,
	INVESTOR_REGISTER_RESET,
	INVESTOR_LIST_FAIL,
	INVESTOR_LIST_REQUEST,
	INVESTOR_LIST_SUCCESS,
	INVESTOR_LIST_RESET,
	INVESTOR_UPDATE_REQUEST,
	INVESTOR_UPDATE_SUCCESS,
	INVESTOR_UPDATE_FAIL,
	INVESTOR_UPDATE_RESET,
	INVESTOR_PRESCRIBERS_PROFILES_REQUEST,
	INVESTOR_PRESCRIBERS_PROFILES_FAIL,
	INVESTOR_PRESCRIBERS_PROFILES_RESET,
	INVESTOR_PRESCRIBERS_PROFILES_SUCCESS,
} from '../constants/investorConstant'

export const investorRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_REGISTER_REQUEST:
			return { loadingInvestorRegister: true }
		case INVESTOR_REGISTER_SUCCESS:
			return {
				succesInvestorRegister: true,
				loadingInvestorRegister: false,
				investorResponse: action.payload,
			}
		case INVESTOR_REGISTER_FAIL:
			return {
				loadingInvestorRegister: false,
				errorInvestorRegister: action.payload,
			}
		case INVESTOR_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const investorGetUsersReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_LIST_REQUEST:
			return { loadingInvestorGetUsers: true }
		case INVESTOR_LIST_SUCCESS:
			return {
				successInvestorGetUsers: true,
				loadingInvestorGetUsers: false,
				investorGetUsersData: action.payload,
			}
		case INVESTOR_LIST_FAIL:
			return {
				loadingInvestorGetUsers: false,
				errorInvestorGetUsersData: action.payload,
			}
		case INVESTOR_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const investorUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_UPDATE_REQUEST:
			return { loadingInvestorUpdate: true }
		case INVESTOR_UPDATE_SUCCESS:
			return {
				succesInvestorUpdate: true,
				loadingInvestorUpdate: false,
				investorResponse: action.payload,
			}
		case INVESTOR_UPDATE_FAIL:
			return {
				loadingInvestorUpdate: false,
				errorInvestorUpdate: action.payload,
			}
		case INVESTOR_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const investorPrescriberProfilesReducer = (state = {}, action) => {
	switch (action.type) {
		case INVESTOR_PRESCRIBERS_PROFILES_REQUEST:
			return { loadingInvestorPrescribers: true }
		case INVESTOR_PRESCRIBERS_PROFILES_SUCCESS:
			return {
				loadingInvestorPrescribers: false,
				prescribers: action.payload,
			}
		case INVESTOR_PRESCRIBERS_PROFILES_FAIL:
			return {
				loadingInvestorPrescribers: false,
				errorInvestorPrescribers: action.payload,
			}
		case INVESTOR_PRESCRIBERS_PROFILES_RESET:
			return {}
		default:
			return state
	}
}
