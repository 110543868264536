export const PROJECT_REGISTER_REQUEST = 'PROJECT_REGISTER_REQUEST'
export const PROJECT_REGISTER_SUCCESS = 'PROJECT_REGISTER_SUCCESS'
export const PROJECT_REGISTER_FAIL = 'PROJECT_REGISTER_FAIL'
export const PROJECT_REGISTER_RESET = 'PROJECT_REGISTER_RESET'

export const PROJECT_LIST_REQUEST = 'PROJECT_LIST_REQUEST'
export const PROJECT_LIST_SUCCESS = 'PROJECT_LIST_SUCCESS'
export const PROJECT_LIST_FAIL = 'PROJECT_LIST_FAIL'
export const PROJECT_LIST_RESET = 'PROJECT_LIST_RESET'

export const SEND_PROJECT_SETTLEMENT_EMAIL_REQUEST = 'SEND_PROJECT_SETTLEMENT_EMAIL_REQUEST'
export const SEND_PROJECT_SETTLEMENT_EMAIL_SUCCESS = 'SEND_PROJECT_SETTLEMENT_EMAIL_SUCCESS'
export const SEND_PROJECT_SETTLEMENT_EMAIL_FAIL = 'SEND_PROJECT_SETTLEMENT_EMAIL_FAIL'
export const SEND_PROJECT_SETTLEMENT_EMAIL_RESET = 'SEND_PROJECT_SETTLEMENT_EMAIL_RESET'

export const PROJECT_FINISHED_LIST_REQUEST = 'PROJECT_FINISHED_LIST_REQUEST'
export const PROJECT_FINISHED_LIST_SUCCESS = 'PROJECT_FINISHED_LIST_SUCCESS'
export const PROJECT_FINISHED_LIST_FAIL = 'PROJECT_FINISHED_LIST_FAIL'
export const PROJECT_FINISHED_LIST_RESET = 'PROJECT_FINISHED_LIST_RESET'

export const PROJECT_TO_INVEST_LIST_REQUEST = 'PROJECT_TO_INVEST_LIST_REQUEST'
export const PROJECT_TO_INVEST_LIST_SUCCESS = 'PROJECT_TO_INVEST_LIST_SUCCESS'
export const PROJECT_TO_INVEST_LIST_FAIL = 'PROJECT_TO_INVEST_LIST_FAIL'
export const PROJECT_TO_INVEST_LIST_RESET = 'PROJECT_TO_INVEST_LIST_RESET'

export const PROJECT_FROM_SALES_LIST_REQUEST = 'PROJECT_FROM_SALES_LIST_REQUEST'
export const PROJECT_FROM_SALES_LIST_SUCCESS = 'PROJECT_FROM_SALES_LIST_SUCCESS'
export const PROJECT_FROM_SALES_LIST_FAIL = 'PROJECT_FROM_SALES_LIST_FAIL'
export const PROJECT_FROM_SALES_LIST_RESET = 'PROJECT_FROM_SALES_LIST_RESET'

export const PROJECT_FROM_PDL_LIQUIDATE_LIST_REQUEST = 'PROJECT_FROM_PDL_LIQUIDATE_LIST_REQUEST'
export const PROJECT_FROM_PDL_LIQUIDATE_LIST_SUCCESS = 'PROJECT_FROM_PDL_LIQUIDATE_LIST_SUCCESS'
export const PROJECT_FROM_PDL_LIQUIDATE_LIST_FAIL = 'PROJECT_FROM_PDL_LIQUIDATE_LIST_FAIL'
export const PROJECT_FROM_PDL_LIQUIDATE_LIST_RESET = 'PROJECT_FROM_PDL_LIQUIDATE_LIST_RESET'

export const PROJECT_BY_ID_REQUEST = 'PROJECT_BY_ID_REQUEST'
export const PROJECT_BY_ID_SUCCESS = 'PROJECT_BY_ID_SUCCESS'
export const PROJECT_BY_ID_FAIL = 'PROJECT_BY_ID_FAIL'
export const PROJECT_BY_ID_RESET = 'PROJECT_BY_ID_RESET'

export const PROJECT_UPDATE_REQUEST = 'PROJECT_UPDATE_REQUEST'
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS'
export const PROJECT_UPDATE_FAIL = 'PROJECT_UPDATE_FAIL'
export const PROJECT_UPDATE_RESET = 'PROJECT_UPDATE_RESET'

export const PROJECT_DELETE_REQUEST = 'PROJECT_DELETE_REQUEST'
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS'
export const PROJECT_DELETE_FAIL = 'PROJECT_DELETE_FAIL'
export const PROJECT_DELETE_RESET = 'PROJECT_DELETE_RESET'

export const PROJECT_REBUILD_CSV_REQUEST = 'PROJECT_REBUILD_CSV_REQUEST'
export const PROJECT_REBUILD_CSV_SUCCESS = 'PROJECT_REBUILD_CSV_SUCCESS'
export const PROJECT_REBUILD_CSV_FAIL = 'PROJECT_REBUILD_CSV_FAIL'
export const PROJECT_REBUILD_CSV_RESET = 'PROJECT_REBUILD_CSV_RESET'

export const PROJECT_DELAYED_LIST_REQUEST = 'PROJECT_DELAYED_LIST_REQUEST'
export const PROJECT_DELAYED_LIST_SUCCESS = 'PROJECT_DELAYED_LIST_SUCCESS'
export const PROJECT_DELAYED_LIST_FAIL = 'PROJECT_DELAYED_LIST_FAIL'
export const PROJECT_DELAYED_LIST_RESET = 'PROJECT_DELAYED_LIST_RESET'

export const REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_REQUEST = 'REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_REQUEST'
export const REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_SUCCESS = 'REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_SUCCESS'
export const REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_FAIL = 'REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_FAIL'
export const REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_RESET = 'REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_RESET'

export const PROJECT_UPDATE_FINISHED_DATE_REQUEST = 'PROJECT_UPDATE_FINISHED_DATE_REQUEST'
export const PROJECT_UPDATE_FINISHED_DATE_SUCCESS = 'PROJECT_UPDATE_FINISHED_DATE_SUCCESS'
export const PROJECT_UPDATE_FINISHED_DATE_FAIL = 'PROJECT_UPDATE_FINISHED_DATE_FAIL'
export const PROJECT_UPDATE_FINISHED_DATE_RESET = 'PROJECT_UPDATE_FINISHED_DATE_RESET'

export const SIG_PROJECT_UPDATE_BY_PDL_REQUEST = 'SIG_PROJECT_UPDATE_BY_PDL_REQUEST'
export const SIG_PROJECT_UPDATE_BY_PDL_SUCCESS = 'SIG_PROJECT_UPDATE_BY_PDL_SUCCESS'
export const SIG_PROJECT_UPDATE_BY_PDL_FAIL = 'SIG_PROJECT_UPDATE_BY_PDL_FAIL'
export const SIG_PROJECT_UPDATE_BY_PDL_RESET = 'SIG_PROJECT_UPDATE_BY_PDL_RESET'

export const PROJECT_PDL_TO_INVEST_LIST_REQUEST = 'PROJECT_PDL_TO_INVEST_LIST_REQUEST'
export const PROJECT_PDL_TO_INVEST_LIST_SUCCESS = 'PROJECT_PDL_TO_INVEST_LIST_SUCCESS'
export const PROJECT_PDL_TO_INVEST_LIST_FAIL = 'PROJECT_PDL_TO_INVEST_LIST_FAIL'
export const PROJECT_PDL_TO_INVEST_LIST_RESET = 'PROJECT_PDL_TO_INVEST_LIST_RESET'

export const PROJECT_ACCEPTED_OFFER_REQUEST = 'PROJECT_ACCEPTED_OFFER_REQUEST'
export const PROJECT_ACCEPTED_OFFER_SUCCESS = 'PROJECT_ACCEPTED_OFFER_SUCCESS'
export const PROJECT_ACCEPTED_OFFER_FAIL = 'PROJECT_ACCEPTED_OFFER_FAIL'
export const PROJECT_ACCEPTED_OFFER_RESET = 'PROJECT_ACCEPTED_OFFER_RESET'
