export const NOTIFICATION_LIST_REQUEST = 'NOTIFICATION_LIST_REQUEST'
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS'
export const NOTIFICATION_LIST_FAIL = 'NOTIFICATION_LIST_FAIL'
export const NOTIFICATION_LIST_RESET = 'NOTIFICATION_LIST_RESET'

export const NOTIFICATION_BY_ID_REQUEST = 'NOTIFICATION_BY_ID_REQUEST'
export const NOTIFICATION_BY_ID_SUCCESS = 'NOTIFICATION_BY_ID_SUCCESS'
export const NOTIFICATION_BY_ID_FAIL = 'NOTIFICATION_BY_ID_FAIL'
export const NOTIFICATION_BY_ID_RESET = 'NOTIFICATION_BY_ID_RESET'

export const NOTIFICATION_BY_USER_REQUEST = 'NOTIFICATION_BY_USER_REQUEST'
export const NOTIFICATION_BY_USER_SUCCESS = 'NOTIFICATION_BY_USER_SUCCESS'
export const NOTIFICATION_BY_USER_FAIL = 'NOTIFICATION_BY_USER_FAIL'
export const NOTIFICATION_BY_USER_RESET = 'NOTIFICATION_BY_USER_RESET'

export const NOTIFICATION_REGISTER_REQUEST = 'NOTIFICATION_REGISTER_REQUEST'
export const NOTIFICATION_REGISTER_SUCCESS = 'NOTIFICATION_REGISTER_SUCCESS'
export const NOTIFICATION_REGISTER_FAIL = 'NOTIFICATION_REGISTER_FAIL'
export const NOTIFICATION_REGISTER_RESET = 'NOTIFICATION_REGISTER_RESET'

export const NOTIFICATION_BY_TYPE_LIST_REQUEST = 'NOTIFICATION_BY_TYPE_LIST_REQUEST'
export const NOTIFICATION_BY_TYPE_LIST_SUCCESS = 'NOTIFICATION_BY_TYPE_LIST_SUCCESS'
export const NOTIFICATION_BY_TYPE_LIST_FAIL = 'NOTIFICATION_BY_TYPE_LIST_FAIL'
export const NOTIFICATION_BY_TYPE_LIST_RESET = 'NOTIFICATION_BY_TYPE_LIST_RESET'
