export const FIXED_RATE_LOANS_LIST_REQUEST = 'FIXED_RATE_LOANS_LIST_REQUEST'
export const FIXED_RATE_LOANS_LIST_SUCCESS = 'FIXED_RATE_LOANS_LIST_SUCCESS'
export const FIXED_RATE_LOANS_LIST_FAIL = 'FIXED_RATE_LOANS_LIST_FAIL'
export const FIXED_RATE_LOANS_LIST_RESET = 'FIXED_RATE_LOANS_LIST_RESET'

export const FIXED_RATE_LOANS_REGISTER_REQUEST = 'FIXED_RATE_LOANS_REGISTER_REQUEST'
export const FIXED_RATE_LOANS_REGISTER_SUCCESS = 'FIXED_RATE_LOANS_REGISTER_SUCCESS'
export const FIXED_RATE_LOANS_REGISTER_FAIL = 'FIXED_RATE_LOANS_REGISTER_FAIL'
export const FIXED_RATE_LOANS_REGISTER_RESET = 'FIXED_RATE_LOANS_REGISTER_RESET'

export const FIXED_RATE_LOANS_UPDATE_BY_ID_REQUEST = 'FIXED_RATE_LOANS_UPDATE_REQUEST'
export const FIXED_RATE_LOANS_UPDATE_BY_ID_SUCCESS = 'FIXED_RATE_LOANS_UPDATE_SUCCESS'
export const FIXED_RATE_LOANS_UPDATE_BY_ID_FAIL = 'FIXED_RATE_LOANS_UPDATE_FAIL'
export const FIXED_RATE_LOANS_UPDATE_BY_ID_RESET = 'FIXED_RATE_LOANS_UPDATE_RESET'

export const FIXED_RATE_LOANS_DELETE_BY_ID_REQUEST = 'FIXED_RATE_LOANS_DELETE_REQUEST'
export const FIXED_RATE_LOANS_DELETE_BY_ID_SUCCESS = 'FIXED_RATE_LOANS_DELETE_SUCCESS'
export const FIXED_RATE_LOANS_DELETE_BY_ID_FAIL = 'FIXED_RATE_LOANS_DELETE_FAIL'
export const FIXED_RATE_LOANS_DELETE_BY_ID_RESET = 'FIXED_RATE_LOANS_DELETE_RESET'
