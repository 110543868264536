export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_UPDATE_MY_INFO_REQUEST = 'USER_UPDATE_MY_INFO_REQUEST'
export const USER_UPDATE_MY_INFO_SUCCESS = 'USER_UPDATE_MY_INFO_SUCCESS'
export const USER_UPDATE_MY_INFO_FAIL = 'USER_UPDATE_MY_INFO_FAIL'
export const USER_UPDATE_MY_INFO_RESET = 'USER_UPDATE_MY_INFO_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_DELETE_RESET = 'USER_DELETE_RESET'

export const USER_REFRESH_TOKEN_REQUEST = 'USER_REFRESH_TOKEN_REQUEST'
export const USER_REFRESH_TOKEN_SUCCESS = 'USER_REFRESH_TOKEN_SUCCESS'
export const USER_REFRESH_TOKEN_FAIL = 'USER_REFRESH_TOKEN_FAIL'
export const USER_REFRESH_TOKEN_RESET = 'USER_REFRESH_TOKEN_RESET'

export const USER_RECOVERY_PASSWORD_REQUEST = 'USER_RECOVERY_PASSWORD_REQUEST'
export const USER_RECOVERY_PASSWORD_SUCCESS = 'USER_RECOVERY_PASSWORD_SUCCESS'
export const USER_RECOVERY_PASSWORD_FAIL = 'USER_RECOVERY_PASSWORD_FAIL'
export const USER_RECOVERY_PASSWORD_RESET = 'USER_RECOVERY_PASSWORD_RESET'

export const PROFILE_GET_NOTES_REQUEST = 'PROFILE_GET_NOTES_REQUEST'
export const PROFILE_GET_NOTES_REQUEST_ADD = 'PROFILE_GET_NOTES_REQUEST_ADD'
export const PROFILE_GET_NOTES_SUCCESS = 'PROFILE_GET_NOTES_SUCCESS'
export const PROFILE_GET_NOTES_FAIL = 'PROFILE_GET_NOTES_FAIL'
export const PROFILE_GET_NOTES_FAIL_RESET = 'PROFILE_GET_NOTES_FAIL_RESET'
export const PROFILE_GET_NOTES_SUCCESS_ADDED = 'PROFILE_GET_NOTES_SUCCESS_ADDED'
export const PROFILE_GET_NOTES_RESET = 'PROFILE_GET_NOTES_RESET'

export const USER_GET_NOTES_REQUEST = 'USER_GET_NOTES_REQUEST'
export const USER_GET_NOTES_REQUEST_ADD = 'USER_GET_NOTES_REQUEST_ADD'
export const USER_GET_NOTES_SUCCESS = 'USER_GET_NOTES_SUCCESS'
export const USER_GET_NOTES_FAIL = 'USER_GET_NOTES_FAIL'
export const USER_GET_NOTES_FAIL_RESET = 'USER_GET_NOTES_FAIL_RESET'
export const USER_GET_NOTES_SUCCESS_ADDED = 'USER_GET_NOTES_SUCCESS_ADDED'
export const USER_GET_NOTES_RESET = 'USER_GET_NOTES_RESET'

export const USER_LOGIN_MFA_CODE_REQUEST = 'USER_LOGIN_MFA_CODE_REQUEST'
export const USER_LOGIN_MFA_CODE_SUCCESS = 'USER_LOGIN_MFA_CODE_SUCCESS'
export const USER_LOGIN_MFA_CODE_FAIL = 'USER_LOGIN_MFA_CODE_FAIL'
export const USER_LOGIN_MFA_CODE_RESET = 'USER_LOGIN_MFA_CODE_RESET'

export const USER_MFA_CODE_VALIDATE_REQUEST = 'USER_MFA_CODE_VALIDATE_REQUEST'
export const USER_MFA_CODE_VALIDATE_SUCCESS = 'USER_MFA_CODE_VALIDATE_SUCCESS'
export const USER_MFA_CODE_VALIDATE_FAIL = 'USER_MFA_CODE_VALIDATE_FAIL'
export const USER_MFA_CODE_VALIDATE_RESET = 'USER_MFA_CODE_VALIDATE_RESET'

export const USER_CONFIRM_PASSWORD_REQUEST = 'USER_CONFIRM_PASSWORD_REQUEST'
export const USER_CONFIRM_PASSWORD_SUCCESS = 'USER_CONFIRM_PASSWORD_SUCCESS'
export const USER_CONFIRM_PASSWORD_FAIL = 'USER_CONFIRM_PASSWORD_FAIL'
export const USER_CONFIRM_PASSWORD_RESET = 'USER_CONFIRM_PASSWORD_RESET'

export const USER_MFA_CODE_DISABLE_REQUEST = 'USER_MFA_CODE_DISABLE_REQUEST'
export const USER_MFA_CODE_DISABLE_SUCCESS = 'USER_MFA_CODE_DISABLE_SUCCESS'
export const USER_MFA_CODE_DISABLE_FAIL = 'USER_MFA_CODE_DISABLE_FAIL'
export const USER_MFA_CODE_DISABLE_RESET = 'USER_MFA_CODE_DISABLE_RESET'
