export const OWNER_PROFILE_WITH_USER_REGISTER_REQUEST = 'OWNER_PROFILE_WITH_USER_REGISTER_REQUEST'
export const OWNER_PROFILE_WITH_USER_REGISTER_SUCCESS = 'OWNER_PROFILE_WITH_USER_REGISTER_SUCCESS'
export const OWNER_PROFILE_WITH_USER_REGISTER_FAIL = 'OWNER_PROFILE_WITH_USER_REGISTER_FAIL'
export const OWNER_PROFILE_WITH_USER_REGISTER_RESET = 'OWNER_PROFILE_WITH_USER_REGISTER_RESET'

export const OWNER_LIST_REQUEST = 'OWNER_LIST_REQUEST'
export const OWNER_LIST_SUCCESS = 'OWNER_LIST_SUCCESS'
export const OWNER_LIST_FAIL = 'OWNER_LIST_FAIL'
export const OWNER_LIST_RESET = 'OWNER_LIST_RESET'

export const OWNER_PROFILE_LIST_REQUEST = 'OWNER_PROFILE_LIST_REQUEST'
export const OWNER_PROFILE_LIST_SUCCESS = 'OWNER_PROFILE_LIST_SUCCESS'
export const OWNER_PROFILE_LIST_FAIL = 'OWNER_PROFILE_LIST_FAIL'
export const OWNER_PROFILE_LIST_RESET = 'OWNER_PROFILE_LIST_RESET'

export const OWNER_PROFILE_BY_ID_REQUEST = 'OWNER_PROFILE_BY_ID_REQUEST'
export const OWNER_PROFILE_BY_ID_SUCCESS = 'OWNER_PROFILE_BY_ID_SUCCESS'
export const OWNER_PROFILE_BY_ID_FAIL = 'OWNER_PROFILE_BY_ID_FAIL'
export const OWNER_PROFILE_BY_ID_RESET = 'OWNER_PROFILE_BY_ID_RESET'

export const OWNER_PROFILE_BY_USER_REQUEST = 'OWNER_PROFILE_BY_USER_REQUEST'
export const OWNER_PROFILE_BY_USER_SUCCESS = 'OWNER_PROFILE_BY_USER_SUCCESS'
export const OWNER_PROFILE_BY_USER_FAIL = 'OWNER_PROFILE_BY_USER_FAIL'
export const OWNER_PROFILE_BY_USER_RESET = 'OWNER_PROFILE_BY_USER_RESET'

export const OWNER_PROFILE_DELETE_REQUEST = 'OWNER_PROFILE_DELETE_REQUEST'
export const OWNER_PROFILE_DELETE_SUCCESS = 'OWNER_PROFILE_DELETE_SUCCESS'
export const OWNER_PROFILE_DELETE_FAIL = 'OWNER_PROFILE_DELETE_FAIL'
export const OWNER_PROFILE_DELETE_RESET = 'OWNER_PROFILE_DELETE_RESET'
